import { variants, TypographyVariant } from './variants';
import { twMerge } from 'tailwind-merge';

export interface TypographyProps<T extends keyof typeof variants> {
  variant?: T;
  className?: string;
  title?: string | number;
  children?: any,
  onClick?: any
}

const Typography = <T extends keyof typeof variants>({
  variant,
  className = '',
  title,
  onClick,
  children
}: TypographyProps<T>) => {
  const variantConfig = (variants as Record<string, TypographyVariant>)[variant || 'regular-default'];

  const { tag, className: typographyClassName } = variantConfig || {};

  const Tag = tag ?? 'p';
  const combinedClassName = twMerge(variant && typographyClassName, className);

  if (title === undefined && children === undefined) {
    return null;
  }

  return (
    <Tag onClick={onClick} className={combinedClassName}>
      {title !== undefined ? title : children}
    </Tag>
  );
};


export default Typography;
