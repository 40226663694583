import relaxMan from 'assets/image/relax-man.svg';
import { useNavigate } from 'react-router-dom';
import * as routes from 'routes/routes';
import { localStorageService } from 'helpers/localStorageService';
import AssessmentFeedbackForm from 'components/AssessmentFeedbackForm';
import Typography from 'ui-kit/Typography';
import { setFeedbackUserdata } from 'store/slices/applicationSlice';
import { assessmentFeedback } from 'store/thunks/applicationThunks';
import useAppDispatch from 'store/hooks/useAppDispatch';
import useAppSelector from 'store/hooks/useAppSelector';

const CandidateApplicationFeedback = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const applicationId = localStorageService.getApplicationId();

  const { applicationStatus, feedback } = useAppSelector(store => store.event);

  const onSave = data => {
    dispatch(setFeedbackUserdata(data.feedback));
  };

  const onSubmit = data => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }

    dispatch(
      assessmentFeedback({
        applicationId,
        data
      })
    );
    navigate(routes.CANDIDATE_APPLICATION_COMPLETED);
  };

  return (
    <div className="mx-[8px] my-5 flex max-w-2xl flex-col gap-5 rounded-lg border border-gray-400 bg-white px-[12px] py-[24px] shadow-med sm:mx-[20px] md:min-w-[700px] md:px-[48px]">
      <div className="p-4">
        <Typography className="text-center" title="Thank you" variant="heading-h2" />
        <Typography
          className="text-center"
          title="You have finished your assessment."
          variant="body-regular"
        />
        <img src={relaxMan} className="mx-auto w-full sm:max-w-sm" alt="man-on-relax" />
      </div>
      <AssessmentFeedbackForm
        onSubmit={onSubmit}
        onSave={onSave}
        feedback={feedback}
        userData={applicationStatus?.user}
      />
    </div>
  );
};

export default CandidateApplicationFeedback;
