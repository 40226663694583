import Typography from 'ui-kit/Typography';
import useAppSelector from 'store/hooks/useAppSelector';

const ArchivedAssessment = () => {
  const { eventData, candidate, applicationStatus, assessmentStatus } = useAppSelector(
    store => store.event
  );

  return (
    <main className="mx-[8px] flex max-w-[524px] flex-col rounded-lg border border-[#C9CCCF] bg-white p-[12px] shadow-med sm:mx-[20px] md:self-center md:p-[48px]">
      <Typography
        className="mb-[16px]"
        variant="heading-h2"
        title={`Sorry ${candidate?.firstName || applicationStatus?.firstName},`}
      />
      <p>
        {eventData?.companyName || 'Company Name'} has archived <b>{assessmentStatus?.name}</b>{' '}
        assessment, so it can't accept new applications.
      </p>
    </main>
  );
};

export default ArchivedAssessment;
