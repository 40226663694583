import { toast } from 'react-toastify';

import { localStorageService } from 'helpers/localStorageService';
import Icon from 'ui-kit/Icon';

export const getToken = () => {
  return localStorageService.getToken();
};

export const booleanClassName = (boolean, trueClassName, falseClassName = '') => {
  return boolean ? trueClassName : falseClassName;
};

export const callToast = (type, message) => {
  switch (type) {
    case 'warning':
      return toast.warning(message);
    case 'error':
      return toast.error(message);
    case 'success':
      return toast.success(message, {
        icon: () => <Icon icon="checked" />
      });
    case 'info':
      return toast.info(message);
    case 'dark':
      return toast.dark(message);
    default:
      return toast(message);
  }
};

export function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const createImage = img =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = img.src;
  });

export default async function getCroppedImg(fileName, userId, imageSrc, pixelCrop, type) {
  imageSrc.setAttribute('crossOrigin', 'anonymous');
  const canvas = document.createElement('canvas');
  const scaleX = imageSrc.naturalWidth / imageSrc.width;
  const scaleY = imageSrc.naturalHeight / imageSrc.height;
  canvas.width = Math.ceil(pixelCrop.width * scaleX);
  canvas.height = Math.ceil(pixelCrop.height * scaleY);
  const ctx = canvas.getContext('2d');
  ctx.drawImage(
    imageSrc,
    pixelCrop.x * scaleX,
    pixelCrop.y * scaleY,
    pixelCrop.width * scaleX,
    pixelCrop.height * scaleY,
    0,
    0,
    pixelCrop.width * scaleX,
    pixelCrop.height * scaleY
  );

  const date = new Date().toTimeString().slice(0, 8).replace(/[:]+/g, '_');

  return dataURLtoFile(
    canvas.toDataURL(type),
    `${fileName + '_' + userId + '_' + date}.${type === 'image/png' ? 'png' : 'jpeg'}`
  );
}

export const getModifyData = data => {
  const options = { month: 'short', day: 'numeric' };

  return new Date(data).toLocaleDateString('en-US', options).replace(/\//g, '.');
};

export const getModifyDataWithYear = data => {
  const options = { month: 'short', day: 'numeric', year: 'numeric' };

  return new Date(data).toLocaleDateString('en-US', options).replace(/\//g, '.');
};

export const getFullName = data =>
  data?.firstName && data?.lastName ? data?.firstName + ' ' + data?.lastName : 'Name Surname';

export const questionMove = (questions, oldIndex, newIndex) => {
  if (newIndex >= questions.length) {
    let steps = newIndex - questions.length + 1;
    while (steps--) {
      questions.push(undefined);
    }
  }
  questions.splice(newIndex, 0, questions.splice(oldIndex, 1)[0]);
  return questions;
};

// export const formatFullTime = milliseconds => {
//   if (typeof milliseconds !== 'number' || isNaN(milliseconds)) {
//     return 'Invalid time limit';
//   }

//   const totalSeconds = Math.floor(milliseconds / 1000);
//   const hours = Math.floor(totalSeconds / 3600);
//   const minutes = Math.floor((totalSeconds % 3600) / 60);
//   const seconds = totalSeconds % 60;

//   const timeComponents = [];
//   if (hours > 0) {
//     timeComponents.push(`${hours} hour${hours !== 1 ? 's' : ''}`);
//   }
//   if (minutes > 0) {
//     timeComponents.push(`${minutes} minute${minutes !== 1 ? 's' : ''}`);
//   }
//   if (seconds > 0) {
//     timeComponents.push(`${seconds} second${seconds !== 1 ? 's' : ''}`);
//   }

//   const formattedTime = timeComponents.join(' ');
//   return formattedTime;
// };

export const secondsToHms = (milliseconds, type = 'small') => {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const showHours = hours > 0;
  const showMinutes =
    minutes >= 1 && (hours > 0 || minutes <= 10 || minutes >= 10 || remainingSeconds > 0);
  const showSeconds = minutes < 1 || (minutes < 10 && remainingSeconds > 0);

  let timeDisplay = '';

  if (showHours) {
    timeDisplay += `${hours}${type === 'big' ? ' hours' : ' hr'}`;
    if (showMinutes) {
      if (type === 'big') {
        timeDisplay += ` ${remainingMinutes}${remainingMinutes === 1 ? ' minute' : ' minutes'}`;
      } else {
        timeDisplay += ` ${remainingMinutes} min`;
      }
    }
  } else if (showMinutes) {
    timeDisplay += `${remainingMinutes}${type === 'big' ? ' minutes' : ' min'}`;
    if (showSeconds) {
      if (type === 'big') {
        timeDisplay += ` ${remainingSeconds}${remainingSeconds === 1 ? ' second' : ' seconds'}`;
      } else {
        timeDisplay += ` ${remainingSeconds} sec`;
      }
    }
  } else if (showSeconds) {
    timeDisplay += `${remainingSeconds}${type === 'big' ? ' seconds' : ' sec'}`;
  }

  return timeDisplay;
};

export const badgetStatus = status => {
  switch (status) {
    case 'draft':
      return 'bg-[#CC8E35] text-white';
    case 'draftHover':
      return 'group-hover:bg-[#FFD79D]';
    case 'published':
      return 'text-white  bg-[#007D6F]';
    case 'publishedHover':
      return 'group-hover:text-white  group-hover:bg-[#007D6F]';
    case 'archived':
      return 'text-white bg-[#6D7175]';
    case 'active':
      return 'text-white bg-green-400';
    case 'disabled':
      return 'text-black bg-[#F6F6F7]';
    case 'invited':
      return 'black bg-[#FFD79D]';
    default:
      return 'text-white  bg-green-400';
  }
};

export const confirmStatus = status => {
  switch (status) {
    case 'draft':
      return 'Publish';
    case 'active':
      return 'Archive';
    case 'archived':
      return 'Re-Publish';
    default:
      return '';
  }
};

export const calculatePercent = (invited, started, completed) => {
  const total = invited + started + completed;
  return number => (number / total) * 100 + '%';
};
