import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { StringParam, useQueryParam } from 'use-query-params';
import Footer from 'components/Footer';
import { CandidateHeader } from '../CandidateHeader/CandidateHeader';
import Loader from 'components/Loader';
import * as routes from 'routes/routes';
import { localStorageService } from 'helpers/localStorageService';
import {
  getApplicationStatus,
  getAssessmentStatus,
  getNextQuestion,
  sendScreenshot
} from 'store/thunks/applicationThunks';
import { setSelectedAnswer } from 'store/slices/applicationSlice';
import useAppDispatch from 'store/hooks/useAppDispatch';
import useAppSelector from 'store/hooks/useAppSelector';
import Webcam from 'react-webcam';

function dataURLtoBlob(dataURL) {
  const arr = dataURL.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

const toastOnline = t => (
  <>
    <div className="flex-1">You're online now</div>
    <div className="flex">
      <button onClick={() => toast.dismiss(t.id)}>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.9375 11.6484C13.2891 12.0391 13.2891 12.625 12.9375 12.9766C12.5469 13.3672 11.9609 13.3672 11.6094 12.9766L7 8.32812L2.35156 12.9766C1.96094 13.3672 1.375 13.3672 1.02344 12.9766C0.632812 12.625 0.632812 12.0391 1.02344 11.6484L5.67188 7L1.02344 2.35156C0.632812 1.96094 0.632812 1.375 1.02344 1.02344C1.375 0.632812 1.96094 0.632812 2.3125 1.02344L7 5.71094L11.6484 1.0625C12 0.671875 12.5859 0.671875 12.9375 1.0625C13.3281 1.41406 13.3281 2 12.9375 2.39062L8.28906 7L12.9375 11.6484Z"
            fill="#5C5F62"
          />
        </svg>
      </button>
    </div>
  </>
);

export const CandidateMain = () => {
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [email] = useQueryParam('email', StringParam);
  const localHash = localStorageService.getHash();
  const applicationId = localStorageService.getApplicationId();
  const webcamRef = useRef(null);
  const [cameraAccess, setCameraAccess] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState(null);

  const {
    applicationStatus,
    assessmentStatus,
    eventQuestion,
    currentTest,
    selectedAnswer,
    loading
  } = useAppSelector(store => store.event);

  const onCompliteQuestionHandler = () => {
    if (loading) {
      return;
    }
    const { testId, sectionId, questionId } = eventQuestion;
    const id = applicationId || applicationStatus?.applicationId;
    dispatch(
      getNextQuestion({
        applicationId: id,
        data: {
          testId,
          sectionId,
          questionId,
          answer: selectedAnswer
        },
        navigate
      })
    );
    dispatch(setSelectedAnswer([]));
  };

  const uploadScreenshot = photo => {
    const formData = new FormData();
    formData.append('screenshot', photo);
    dispatch(sendScreenshot({ file: formData, id: applicationId }));
  };

  const capturePhoto = () => {
    if (webcamRef.current) {
      const photo = webcamRef.current.getScreenshot({ width: 640, height: 480 });

      // Convert the base64 data URL to a Blob
      const blob = dataURLtoBlob(photo);

      uploadScreenshot(blob);
    }
  };

  useEffect(() => {
    if (!email || localStorageService.getApplicationId()) {
      return;
    }
    const preparedHash = hash.substring(1);
    dispatch(
      getApplicationStatus({
        data: { hash: preparedHash, email },
        navigate
      })
    );
  }, [email, hash]);

  useEffect(() => {
    let preparedHash = applicationStatus?.hash || hash.substring(1) || localHash;
    if (email) {
      preparedHash = applicationStatus?.hash;
    }
    if (!preparedHash) {
      return;
    }
    dispatch(getAssessmentStatus({ data: { hash: preparedHash }, navigate }));
  }, [applicationStatus?.hash, hash, email]);

  useEffect(() => {
    if (assessmentStatus?.status === 'archived') {
      navigate(routes.CANDIDATE_ASSESSMENT_ARCHIVED);
    }
  }, [assessmentStatus?.status]);

  useEffect(() => {
    const handleStatusChange = () => {
      if (navigator.onLine) {
        toast.dismiss();
        toast(toastOnline, {
          duration: 4000,
          position: 'left-bottom',
          className: 'rounded-br-2xl border-gray-300 px-4 py-3 text-base w-80',
          icon: (
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1421_30185)">
                <rect width="32" height="32" fill="white" fillOpacity="0.01" />
                <path
                  d="M15.9609 20.375C14.7891 20.375 13.8125 21.3906 13.8125 22.5625C13.8125 23.7734 14.7891 24.75 15.9609 24.75C17.1328 24.75 18.1875 23.7734 18.1875 22.5625C18.1875 21.3906 17.1719 20.375 15.9609 20.375ZM28.1875 11.8984C25.1016 8.96875 20.6484 7.25 16 7.25C11.3125 7.25 6.85938 8.96875 3.77344 11.8984C3.38281 12.25 3.38281 12.8359 3.73438 13.2266C4.08594 13.6172 4.67188 13.6172 5.0625 13.2656C7.79688 10.6484 11.7812 9.125 16 9.125C20.1797 9.125 24.1641 10.6484 26.8984 13.2656C27.0938 13.4219 27.3281 13.5 27.5234 13.5C27.7578 13.5 27.9922 13.4219 28.1875 13.2266C28.5781 12.8359 28.5781 12.25 28.1875 11.8984ZM16 14.125C12.9922 14.125 10.2188 15.2188 8.14844 17.2109C7.75781 17.5625 7.75781 18.1875 8.10938 18.5391C8.46094 18.9297 9.08594 18.9297 9.4375 18.5781C11.1562 16.9375 13.4609 16 16 16C18.5 16 20.8047 16.9375 22.5234 18.5781C22.7188 18.7344 22.9141 18.8125 23.1484 18.8125C23.3828 18.8125 23.6172 18.7344 23.8125 18.5391C24.1641 18.1875 24.1641 17.5625 23.7734 17.2109C21.7422 15.2188 18.9688 14.125 16 14.125Z"
                  fill="#007D6F"
                />
              </g>
              <defs>
                <clipPath id="clip0_1421_30185">
                  <rect width="32" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )
        });
      } else {
        toast.loading(t => <div className="flex-1">You're offline now</div>, {
          position: 'left-bottom',
          className: 'rounded-br-2xl border-gray-300 px-4 py-3 text-base w-80',
          icon: (
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1421_30202)">
                <rect width="32" height="32" fill="white" fillOpacity="0.01" />
                <path
                  d="M8.14844 17.2109C7.75781 17.5625 7.75781 18.1875 8.10938 18.5391C8.46094 18.9297 9.08594 18.9297 9.4375 18.5781C10.2578 17.7578 11.2734 17.1328 12.3281 16.7031L10.6875 15.4141C9.75 15.8828 8.89062 16.5078 8.14844 17.2109ZM3.77344 11.8984C3.38281 12.25 3.38281 12.8359 3.73438 13.2266C4.08594 13.6172 4.67188 13.6172 5.0625 13.2656C5.49219 12.8359 5.96094 12.4844 6.46875 12.1328L4.94531 10.9219C4.51562 11.2344 4.125 11.5469 3.77344 11.8984ZM15.9609 20.375C14.7891 20.375 13.8125 21.3906 13.8125 22.5625C13.8125 23.7734 14.7891 24.75 15.9609 24.75C17.1719 24.75 18.1875 23.7734 18.1875 22.5625C18.1875 21.3906 17.1719 20.375 15.9609 20.375ZM17.6797 16.1562C19.5156 16.4688 21.1953 17.2891 22.5234 18.5781C22.7188 18.7344 22.9141 18.8125 23.1484 18.8125C23.3828 18.8125 23.6172 18.7344 23.8125 18.5391C24.1641 18.1875 24.1641 17.5625 23.7734 17.2109C21.7422 15.2188 18.9688 14.125 16 14.125C15.7266 14.125 15.4531 14.1641 15.1797 14.2031L10.1016 10.2188C11.9375 9.51562 13.9297 9.125 16 9.125C20.1797 9.125 24.1641 10.6484 26.8984 13.2656C27.0938 13.4219 27.3281 13.5 27.5234 13.5C27.7578 13.5 27.9922 13.4219 28.1875 13.2266C28.5391 12.8359 28.5391 12.25 28.1484 11.8984C25.1016 8.96875 20.6484 7.25 15.9609 7.25C13.3047 7.25 10.6875 7.83594 8.38281 8.89062L4.98438 6.23438C4.59375 5.88281 4.00781 5.96094 3.69531 6.39062C3.34375 6.78125 3.42188 7.36719 3.85156 7.67969L26.9766 25.8047C27.1328 25.9609 27.3281 26 27.5234 26C27.7969 26 28.0703 25.8828 28.2266 25.6484C28.5781 25.2578 28.5 24.6719 28.0703 24.3594L17.6797 16.1562Z"
                  fill="#D1361A"
                />
              </g>
              <defs>
                <clipPath id="clip0_1421_30202">
                  <rect width="32" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )
        });
      }
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', handleStatusChange);
    window.addEventListener('offline', handleStatusChange);

    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);

  return (
    <div className="disable-select flex h-screen max-h-full flex-col justify-between">
      {loading && <Loader />}
      <CandidateHeader
        {...assessmentStatus}
        eventQuestion={eventQuestion}
        onCompliteQuestionHandler={onCompliteQuestionHandler}
        currentTest={currentTest}
      />
      {(pathname === routes.CANDIDATE_QUESTION ||
        pathname === routes.CANDIDATE_CAMERA_SETUP ||
        pathname === routes.CANDIDATE_ROADMAP ||
        pathname === routes.CANDIDATE_SECTION_INSTRUCTIONS ||
        pathname === routes.CANDIDATE_TEST_FEEDBACK ||
        pathname === routes.CANDIDATE_APPLICATION_VERIFY) && (
        <Webcam
          videoConstraints={{
            deviceId: selectedCamera?.value,
            aspectRatio: 1.7
          }}
          height={0}
          width={0}
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
        />
      )}
      {!isOnline && <div className="fixed inset-0 z-10 h-full w-full bg-black bg-opacity-50"></div>}
      <main className="my-[20px] flex-1 md:my-10">
        <div className="flex h-full items-start justify-center">
          <Outlet
            context={{
              capturePhoto,
              cameraAccess,
              setCameraAccess,
              selectedCamera,
              setSelectedCamera
            }}
          />
        </div>
      </main>
      <Footer candidate />
    </div>
  );
};
