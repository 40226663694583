import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { secondsToHms } from 'utils/helpers';
import * as routes from 'routes/routes';
import { HtmlTextReplacer } from 'components/HtmlTextReplacer';
import { localStorageService } from 'helpers/localStorageService';
import { CoverTest } from 'components/CoverTest';
import { IconTest } from 'components/IconTest';
import Typography from 'ui-kit/Typography';
import Icon from 'ui-kit/Icon';
import Button from 'ui-kit/Button';
import { getRoadmapStatus, getSectionInstructions } from 'store/thunks/applicationThunks';
import useAppDispatch from 'store/hooks/useAppDispatch';
import useAppSelector from 'store/hooks/useAppSelector';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

export const CandidateRoadmap = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const applicationId = localStorageService.getApplicationId();

  const { applicationStatus, currentTest, sectionInstructions, roadmap } = useAppSelector(
    store => store.event
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNavigate = () => {
    const { search, hash } = location;
    const url = `${search}${hash}`;
    sectionInstructions
      ? navigate(routes.CANDIDATE_SECTION_INSTRUCTIONS + url)
      : navigate(routes.CANDIDATE_QUESTION + url);
  };

  useEffect(() => {
    if (!applicationId && applicationStatus?.applicationId) {
      return;
    }
    const id = applicationId || applicationStatus?.applicationId;
    dispatch(getSectionInstructions(id));
    dispatch(getRoadmapStatus({ id, navigate }));
  }, [applicationStatus?.applicationId, applicationId]);

  useEffect(() => {
    const handlePopstate = () => {
      window.history.go(1);
    };

    window.addEventListener('popstate', handlePopstate);
    return () => {
      window.removeEventListener('popstate', handlePopstate);
      window.history.pushState(null, null, window.location.pathname);
    };
  }, []);

  const currentIndex = roadmap.findIndex(item => item.isCurrent);

  const startTest = () => {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      /* Safari */
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
      /* IE11 */
      document.documentElement.msRequestFullscreen();
    }
    handleNavigate();
  };

  useEffect(() => {
    const handleCopy = event => {
      event.preventDefault();
    };

    const handlePaste = event => {
      event.preventDefault();
    };

    const handleContextMenu = event => {
      event.preventDefault();
    };

    document.addEventListener('copy', handleCopy);
    document.addEventListener('paste', handlePaste);
    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('copy', handleCopy);
      document.removeEventListener('paste', handlePaste);
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  return (
    <div className="mx-[8px] flex w-[100%] max-w-[1050px] flex-col rounded-lg border border-[#C9CCCF] bg-white sm:mx-[20px] sm:flex-row">
      <div
        className="flex flex-1 flex-col gap-3 rounded-l-lg border border-r-[#C9CCCF] bg-[#FAFBFB] p-[12px]
        sm:py-[30px] sm:pl-[48px] sm:pr-[30px]">
        <Typography
          className="text-[20px] font-medium text-[#6D7175] sm:text-[24px]"
          variant="heading-h2"
          title="Assessment Roadmap"
        />
        {roadmap?.map(({ name, timeLimit, isCurrent }, idx) => (
          <div
            key={name}
            className={`flex justify-between ${
              currentIndex !== 0 ? 'pl-[26px] sm:pl-[26px]' : ''
            }`}>
            <div className="flex items-center gap-[10px]">
              {idx < currentIndex && (
                <Icon className="ml-[-26px]" color="#6D7175" icon={faCheck} size={16} />
              )}
              <Typography
                className={`${isCurrent ? 'font-semibold text-[#007D6F]' : 'font-normal'}`}
                variant="body-regular"
                title={name}
              />
            </div>
            <Typography
              className={`${
                isCurrent ? 'font-semibold text-[#007D6F]' : 'font-normal text-[#6D7175]'
              }`}
              variant="body-regular"
              title={secondsToHms(timeLimit)}
            />
          </div>
        ))}
      </div>
      <div className="flex flex-2 flex-col">
        <CoverTest url={currentTest?.cover?.url} size={120} />
        <div className="flex flex-col p-[18px] sm:p-[24px] sm:pb-[30px]">
          <div className="flex items-center gap-3">
            <IconTest url={currentTest?.icon?.url} size={48} />
            <Typography
              variant="heading-h2"
              className="text-[22px] sm:text-[26px]"
              title={currentTest?.name}
            />
          </div>
          <HtmlTextReplacer
            style={{ wordBreak: 'break-word' }}
            className="mt-3 break-words text-base"
            html={currentTest?.summary}
          />
          {currentTest?.name && (
            <Button
              className="mt-6 max-w-[100%] sm:max-w-max"
              variant="primary"
              title={`Start ${currentTest?.name}`}
              onClick={startTest}
            />
          )}
        </div>
      </div>
    </div>
  );
};
