import axios from 'axios';
import { localStorageService } from 'helpers/localStorageService';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorageService.getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
