import React from 'react';
import { useEffect, useState } from 'react';
import Typography from 'ui-kit/Typography';

const Countdown = ({ timeLimit, question, onTimeUp, timeLeft }) => {
  const [remainingTime, setRemainingTime] = useState(Math.round(timeLeft / 1000));

  useEffect(() => {
    if (remainingTime === 0) {
      onTimeUp();
      setRemainingTime(-1);
    }
  }, [remainingTime, onTimeUp]);

  useEffect(() => {
    setRemainingTime(Math.round(timeLeft / 1000));
    const interval = setInterval(() => {
      setRemainingTime(prevRemainingTime => prevRemainingTime - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [timeLeft, question]);

  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;

  const remainingTimePercentage = ((remainingTime * 1000) / timeLimit) * 100;

  let progressBarColorClass = '';
  let textColorClass = '';
  if (remainingTimePercentage <= 33) {
    progressBarColorClass = 'bg-[#FED3D1]';
    textColorClass = 'text-[#AF294E]';
  } else if (remainingTimePercentage <= 66) {
    progressBarColorClass = 'bg-[#FFD79D]';
    textColorClass = 'text-[#7E5700]';
  } else {
    progressBarColorClass = 'bg-[#DBDDDF]';
    textColorClass = 'text-[#6D7175]';
  }

  return (
    <div className="relative flex h-[18px] w-full items-center justify-center rounded-sm border border-gray-300 bg-white md:w-[350px]">
      <div
        className={`absolute right-0 bottom-0 h-[17px] rounded-sm ${progressBarColorClass}`}
        style={{ width: `${remainingTimePercentage}%` }}
      />
      <Typography
        className={`${textColorClass} z-10`}
        variant="small-emphasis"
        title={`Time Limit ${minutes}:${seconds.toString().padStart(2, '0')}`}
      />
    </div>
  );
};

export default React.memo(Countdown);
