import { useNavigate } from 'react-router-dom';
import * as routes from 'routes/routes';
import {
  age,
  employment,
  gender,
  industry,
  occupation,
  educationLevel,
  years
} from 'config/selectConfig';
import relaxMan from 'assets/image/relax-man.svg';
import { localStorageService } from 'helpers/localStorageService';
import { SelectCustom } from 'ui-kit/SelectCustom';
import Typography from 'ui-kit/Typography';
import Form from 'ui-kit/Form';
import Button from 'ui-kit/Button';
import { setFeedbackUserdata } from 'store/slices/applicationSlice';
import { getCountries, testFeedback } from 'store/thunks/applicationThunks';
import useAppDispatch from 'store/hooks/useAppDispatch';
import { useEffect } from 'react';
import useAppSelector from 'store/hooks/useAppSelector';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';

export const CandidateTestFeedback = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const testId = localStorageService.getTestId();
  const testName = localStorageService.getTestName();
  const applicationId = localStorageService.getApplicationId();
  const stepNumber = +localStorageService.getTestIndex();

  const { countries } = useAppSelector(store => store.event);

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  const onSubmit = data => {
    dispatch(setFeedbackUserdata(data.feedback));
    dispatch(
      testFeedback({
        applicationId,
        data: { testId: testId, feedback: data.feedback }
      })
    );
    navigate(routes.CANDIDATE_ROADMAP);
  };

  return (
    <section className="mx-[8px] mb-[131px] flex w-[700px] flex-col justify-center rounded-xl border border-gray-400 bg-white py-6 px-[12px] shadow-med sm:mx-[20px] md:mx-10 md:px-12 ">
      <Typography
        variant="heading-h2"
        title={`You've completed ${testName} Test!`}
        style={{ wordBreak: 'break-word' }}
        className="mb-3 break-words md:mb-5"
      />
      <Typography
        variant="body-regular"
        title="Take a moment to relax, before you continue."
        className="mb-5"
      />
      <p className="mb-5 min-h-[274px]">
        <img src={relaxMan} className="mx-auto w-full sm:max-w-sm" alt="man-on-relax" />
      </p>
      <div className="mb-5 w-full border-t border-[#D2D5D8]" />
      <Form onSubmit={onSubmit} className="flex flex-col gap-3 ">
        {stepNumber < 5 && (
          <Typography
            variant="heading-h3"
            title={'Tell us more about yourself for analytics, please'}
            className="my-5 text-center"
          />
        )}
        {stepNumber === 0 && (
          <SelectCustom name="feedback.ethnicity" placeholder="Country" options={countries} />
        )}
        {stepNumber === 1 && (
          <div className="flex flex-col gap-4 sm:flex-row">
            <div className="flex-1">
              <SelectCustom
                isSearchable={false}
                name="feedback.age"
                placeholder="Age"
                options={age}
              />
            </div>
            <div className="flex-1">
              <SelectCustom
                isSearchable={false}
                name="feedback.gender"
                placeholder="Gender"
                options={gender}
              />
            </div>
          </div>
        )}
        {stepNumber === 2 && (
          <div className="flex flex-col gap-4 sm:flex-row">
            <div className="flex-1">
              <SelectCustom name="feedback.industry" placeholder="Industry" options={industry} />
            </div>
            <div className="flex-1">
              <SelectCustom
                name="feedback.occupation"
                placeholder="Occupation"
                options={occupation}
              />
            </div>
          </div>
        )}
        {stepNumber === 3 && (
          <div className="flex flex-col gap-4 sm:flex-row">
            <div className="flex-1">
              <SelectCustom
                name="feedback.employment"
                placeholder="Employment"
                options={employment}
              />
            </div>
            <div className="flex-1">
              <SelectCustom
                isSearchable={false}
                name="feedback.experince"
                placeholder="Years of experience of role"
                options={years}
              />
            </div>
          </div>
        )}
        {stepNumber === 4 && (
          <div className="flex flex-col gap-4 sm:flex-row">
            <div className="flex-1">
              <SelectCustom
                name="feedback.education"
                placeholder="Highest education level"
                options={educationLevel}
              />
            </div>
          </div>
        )}
        <Button
          iconEnd={faArrowRight}
          iconSize={14}
          type="sumbit"
          variant="primary"
          className="mt-2 w-full justify-center">
          Next Test
        </Button>
      </Form>
    </section>
  );
};
