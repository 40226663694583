import { StringParam, useQueryParam } from 'use-query-params';
import { useLocation } from 'react-router-dom';
import CandidateCheckForm from 'components/CandidateCheckForm';
import Typography from 'ui-kit/Typography';
import useAppSelector from 'store/hooks/useAppSelector';
import { useEffect } from 'react';

export const CandidateSingUp = () => {
  const [email] = useQueryParam('email', StringParam);
  const { hash } = useLocation();

  const { applicationStatus, assessmentStatus } = useAppSelector(store => store.event);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="container mx-[8px] max-w-[576px] rounded-xl border border-gray-400 bg-white py-6 px-[12px] shadow-med sm:mx-[20px] md:px-12">
      {!email ? (
        <div className="flex flex-col gap-[6px]">
          <Typography variant="heading-h5" title="Let's get started" />
          <Typography
            variant="body-regular"
            title="Please enter your email address to access the Hirenest pre-employment assessment and begin your journey to securing your dream job."
          />
        </div>
      ) : (
        <Typography variant="heading-h5" title="Nice to meet you" />
      )}
      <CandidateCheckForm
        email={email}
        assessmentHash={applicationStatus?.hash || hash.substring(1)}
        applicationId={applicationStatus?.applicationId}
        enableCamera={assessmentStatus?.antiCheat?.enableCamera}
      />
    </main>
  );
};
