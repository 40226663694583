import React, { useEffect } from 'react';

import { useNavigate, useLocation, unstable_HistoryRouter as Router } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import { Provider } from 'react-redux';
import { SmartToaster, toast } from 'react-smart-toaster';
import { QueryParamProvider } from 'use-query-params';
import smartlookClient from 'smartlook-client';
import { ToastContainer, Flip } from 'react-toastify';
import { Toaster } from 'react-hot-toast';
import { AppRoute } from './routes';

import 'react-toastify/dist/ReactToastify.css';
import history from 'helpers/useHistory/useHistory';
import { localStorageService } from 'helpers/localStorageService';
import { store } from 'store/store';
import { resetErrors } from 'store/slices/applicationSlice';
import useAppDispatch from 'store/hooks/useAppDispatch';
import { Chart, CategoryScale, LinearScale, BarElement } from 'chart.js';

Chart.register(CategoryScale, LinearScale, BarElement);

const options = {
  autoConfig: true,
  debug: false
};

const RouteAdapter = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const adaptedHistory = React.useMemo(
    () => ({
      replace(location) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location) {
        navigate(location, { replace: false, state: location.state });
      }
    }),
    [navigate]
  );

  useEffect(() => {
    dispatch(resetErrors());
  }, [location]);

  return children({ history: adaptedHistory, location });
};

function App() {
  const currentApp = window.location.origin;
  const role = localStorageService.getRole();

  useEffect(() => {
    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, options);
    ReactPixel.pageView();
    if (currentApp === 'https://app.hirenest.com') {
      smartlookClient.init('dc42f619c74367699de95b31987f75dc2d3a48ac', { region: 'eu' });
      smartlookClient.record({ forms: true, ips: true, emails: true, numbers: true });
    }
  }, [currentApp, role]);

  return (
    <Provider store={store}>
      <Router history={history}>
        <QueryParamProvider ReactRouterRoute={RouteAdapter}>
          <AppRoute />
        </QueryParamProvider>
        <SmartToaster store={toast} lightBackground={true} position={'top-right'} />
        <ToastContainer
          bodyClassName={() => 'items-center flex font-sans text-base w-full p-2.5'}
          position="top-right"
          autoClose={2000}
          hideProgressBar
          transition={Flip}
          style={{ width: 'auto' }}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Toaster />
      </Router>
    </Provider>
  );
}

export default App;
