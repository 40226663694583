import certificateFooter from 'assets/certificateFooter.png';
import { Link } from 'react-router-dom';
import davisBrand from 'assets/certificateBrands/davis.png';
import krachtBrand from 'assets/certificateBrands/kracht.png';
import napaBrand from 'assets/certificateBrands/napa.png';
import rochesterBrand from 'assets/certificateBrands/rochester.png';
import symantechBrand from 'assets/certificateBrands/symantech.png';
import uscBrand from 'assets/certificateBrands/usc.png';

export const FooterCertificate = () => {
  const href =
    process.env.REACT_APP_ENV === 'production'
      ? 'https://app.hirenest.com'
      : 'https://app.hirenestapp.com';
  return (
    <>
      <div
        className="flex flex-col items-center justify-center px-0 py-[24px] sm:px-[20px] sm:pb-[36px]"
        style={{ background: 'linear-gradient(99.09deg, #0A8778 0%, #005349 100%)' }}>
        <div className="mt-6 text-center text-[20px] font-semibold text-[#FFFFFF] md:text-[24px]">
          <span
            className="relative mr-[10px] after:absolute after:left-[-5px] after:right-[-2px] after:top-[50%]
                after:h-[16px] after:bg-[#FFFFFF] after:opacity-[.2] after:content-['']">
            Boost Productivity:
          </span>
          Join 2000+ Companies Using Hirenest vs. CV’s
        </div>
        <div className="mt-12 mb-9 flex flex-wrap items-center justify-center gap-8 md:gap-16">
          <div>
            <img className="h-[25px] md:h-[35px]" src={davisBrand} />
          </div>
          <div>
            <img className="h-[25px] md:h-[35px]" src={krachtBrand} />
          </div>
          <div>
            <img className="h-[25px] md:h-[35px]" src={napaBrand} />
          </div>
          <div>
            <img className="h-[25px] md:h-[35px]" src={rochesterBrand} />
          </div>
          <div>
            <img className="h-[25px] md:h-[35px]" src={symantechBrand} />
          </div>
          <div>
            <img className="h-[25px] md:h-[35px]" src={uscBrand} />
          </div>
        </div>
      </div>
      <div
        className="mt-[24px] mb-[36px] flex flex-col-reverse flex-wrap items-center justify-center
        gap-8 px-[20px]
      sm:mb-[60px] sm:mt-[72px] sm:flex-row sm:flex-nowrap sm:px-0 md:mb-[96px] md:mt-[144px]">
        <div className="w-full sm:max-w-[402px] md:max-w-[466px]">
          <div className="mb-[12px] text-[20px] font-semibold md:text-[32px]">
            Our platform will answer your questions:
          </div>
          <div className="mb-[12px] flex">
            <div className="mr-[12px] h-[20px] w-[20px] text-[#007D6E]">
              <i className="fa-solid fa-circle-check"></i>
            </div>
            What are your candidate’s cognitive abilities?
          </div>
          <div className="mb-[12px] flex">
            <div className="mr-[12px] h-[20px] w-[20px] text-[#007D6E]">
              <i className="fa-solid fa-circle-check"></i>
            </div>
            Whats your candidate’s capacity to learn and grow?
          </div>
          <div className="mb-[12px] flex">
            <div className="mr-[12px] h-[20px] w-[20px] text-[#007D6E]">
              <i className="fa-solid fa-circle-check"></i>
            </div>
            How will your candidates behave in the workplace?
          </div>
          <div className="mb-[12px] flex">
            <div className="mr-[12px] h-[20px] w-[20px] text-[#007D6E]">
              <i className="fa-solid fa-circle-check"></i>
            </div>
            What drives your candidates to engage in action?
          </div>
          <div className="mt-[20px] flex flex-col sm:flex-row">
            <Link
              to={href}
              className="mt-3 mr-[12px] flex h-[51px] w-full items-center justify-center rounded-xl bg-[#FB6232] text-base text-white sm:w-[217px]">
              Start Free Trial{' '}
              <i className="fa-light fa-chevron-right ml-[14px] h-[13px] w-[7px]"></i>
            </Link>
            <a
              className="mt-3 flex h-[51px] w-full items-center justify-center rounded-xl border border-[#FB6232] bg-[#FFFFFF] text-base text-[#FB6232] sm:w-[158px]"
              href="https://hirenest.com/product-tour/?utm_source=certificate">
              Product tour
            </a>
          </div>
        </div>
        <div className="w-full sm:w-auto">
          <img className="h-auto w-[388px] sm:w-[220px] md:w-[466px]" src={certificateFooter} />
        </div>
      </div>
    </>
  );
};
