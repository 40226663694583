import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import Button from 'ui-kit/Button';
import Icon from 'ui-kit/Icon';
import Typography from 'ui-kit/Typography';
import Webcam from 'react-webcam';
import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import * as routes from 'routes/routes';
import { SelectCustom } from 'ui-kit/SelectCustom';
import Form from 'ui-kit/Form';

export const CandidateCameraSetUp = () => {
  const navigate = useNavigate();
  const [availableCameras, setAvailableCameras] = useState([]);
  const { webcamRef, cameraAccess, setCameraAccess, setSelectedCamera, selectedCamera } =
    useOutletContext();

  useEffect(() => {
    // Request camera access when the component mounts
    if (!cameraAccess) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(() => {
          setCameraAccess(true);
          fetchAvailableCameras();
        })
        .catch(error => {
          console.error('Error accessing the camera:', error);
        });
    }
  }, [cameraAccess]);

  const fetchAvailableCameras = () => {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      const videoDevices = devices.filter(device => device.kind === 'videoinput');
      setAvailableCameras(videoDevices);
      setSelectedCamera({ label: videoDevices[0]?.label, value: videoDevices[0]?.deviceId });
    });
  };

  const handleCameraChange = data => {
    setSelectedCamera(data);
  };

  return (
    <div className="mx-[8px] flex max-w-[1100px] flex-col gap-[24px] rounded-md border-[0.5px] border-[#C9CCCF] bg-white py-[24px] px-3 shadow-med sm:mx-[20px] md:px-[48px]">
      <div className="flex flex-col gap-[32px] md:flex-row">
        <div className="flex flex-1 flex-col gap-3">
          <Typography variant="heading-h2" title="Camera Setup" />
          <Typography variant="regular-default">
            We use camera images to ensure fairness for everyone.
            <p className="mt-3">Make sure that you are in front of your camera.</p>
          </Typography>
          {cameraAccess ? (
            <Form
              defaultValues={{
                selectedCamera
              }}
              className="flex flex-col gap-3 md:gap-6 ">
              <Webcam
                videoConstraints={{
                  deviceId: selectedCamera?.value,
                  height: { max: 284 },
                  aspectRatio: 1.7
                }}
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
              />
              <SelectCustom
                isSearchable={false}
                isClearable={false}
                onChangeValue={handleCameraChange}
                name="selectedCamera"
                options={availableCameras.map(camera => {
                  return { value: camera.deviceId, label: camera.label };
                })}
              />
            </Form>
          ) : (
            <div className="flex gap-3 rounded border border-[#FFF5EA] bg-[#FFF5EA] p-3 pl-[18px] shadow-med">
              <Icon className="mt-[2px]" icon={faTriangleExclamation} color="#BB8100" size={20} />
              <div className="flex flex-col">
                <Typography
                  variant="heading-h6"
                  title="Please provide permission to use the camera"
                />
                <Typography variant="regular-default">
                  <p className="mt-3">Employers trust candidates who turn on the camera more.</p>
                  <p className="mt-3">
                    It seems your camera blocked. No worries, we've got you covered. To enable your
                    camera, follow these simple steps:
                  </p>
                  <ul className="ml-7 mt-3">
                    <li>
                      <span className="ml-[-13px]">&bull;</span> Click on the camera blocked icon in
                      your browser's address bar.
                    </li>
                    <li>
                      <span className="ml-[-13px]">&bull;</span> Reload this page.
                    </li>
                  </ul>
                </Typography>
              </div>
            </div>
          )}
          <Typography
            className="text-[#8C9196]"
            variant="regular-default"
            title="Your privacy matters to us. Your webcam is only activated during the assessment and never used without your permission."
          />
        </div>
        <Button
          onClick={() => navigate(routes.CANDIDATE_ROADMAP)}
          className="block md:hidden"
          variant="primary"
          title="Start Assessment"
        />
        <div className="flex flex-1 flex-col gap-3">
          <Typography variant="heading-h5" title="Trouble with your webcam?" />
          <Typography variant="regular-default">
            <p>Please make sure that your browser has been authorized to access your camera.</p>
            <p className="mt-3">Ensure you are using a compatible browser.</p>
            <p className="mt-3">
              If you have multiple camera devices, ensure that both your browser and our website
              have been granted permission to use the correct device.
            </p>
            <p className="mt-3">
              Attempt to start the assessment in incognito mode or a private window.
            </p>
            <p className="mt-3">Ensure that your camera drivers and web browser are current.</p>
            <p className="mt-3">
              Reboot your device and attempt to access the assessment once more using the link
              provided in the invitation email.
            </p>
          </Typography>
        </div>
      </div>
      <Button
        onClick={() => navigate(routes.CANDIDATE_ROADMAP)}
        className="m-auto hidden md:block"
        variant="primary"
        title="Start Assessment"
      />
    </div>
  );
};
