import { useEffect, useMemo, useState } from 'react';
import { shuffle } from 'lodash';
import Attachment from 'components/Attachment';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { HtmlTextReplacer } from 'components/HtmlTextReplacer';
import { localStorageService } from 'helpers/localStorageService';
import SelectAnswer from 'components/SelectAnswer/SelectAnswer';
import Form from 'ui-kit/Form';
import Textarea from 'ui-kit/Textarea';
import Typography from 'ui-kit/Typography';
import List from 'ui-kit/List';
import Button from 'ui-kit/Button';
import {
  getNextQuestion,
  getSectionInstructions,
  antiCheatEvent
} from 'store/thunks/applicationThunks';
import { setSelectedAnswer } from 'store/slices/applicationSlice';
import useAppDispatch from 'store/hooks/useAppDispatch';
import useAppSelector from 'store/hooks/useAppSelector';

export const CandidateQuestion = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [essayText, setEssayText] = useState('');

  const { applicationStatus, eventQuestion, selectedAnswer } = useAppSelector(store => store.event);

  const applicationId = localStorageService.getApplicationId() || applicationStatus?.applicationId;

  const { capturePhoto } = useOutletContext();

  useEffect(() => {
    // Start capturing a photo every 30 seconds
    const intervalId = setInterval(capturePhoto, 30000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [capturePhoto]);

  useEffect(() => {
    const handlePopstate = () => {
      window.history.go(1);
    };

    window.addEventListener('popstate', handlePopstate);
    return () => {
      window.removeEventListener('popstate', handlePopstate);
      window.history.pushState(null, null, window.location.pathname);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [eventQuestion]);

  const isSelectedAnswer = id => selectedAnswer.some(answer => answer === id);

  const isAllAttachments = useMemo(() => {
    return !eventQuestion?.answers?.some(({ text }) => text);
  }, [eventQuestion]);

  const sendPasteEvent = () => {
    const id = applicationStatus?.applicationId ? applicationStatus?.applicationId : applicationId;
    dispatch(
      antiCheatEvent({
        applicationId: id,
        data: { param: 'pasteDetection' }
      })
    );
  };

  const sendFullScreenModeEvent = () => {
    const id = applicationStatus?.applicationId ? applicationStatus?.applicationId : applicationId;
    if (!document.fullscreenElement) {
      dispatch(
        antiCheatEvent({
          applicationId: id,
          data: { param: 'fullScreenMode' }
        })
      );
    }
  };

  const sendMouseInAssessmentEvent = () => {
    const id = applicationStatus?.applicationId ? applicationStatus?.applicationId : applicationId;
    dispatch(
      antiCheatEvent({
        applicationId: id,
        data: { param: 'mouseInAssessment' }
      })
    );
  };

  useEffect(() => {
    const handleKeyDown = event => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
        event.preventDefault(); // Prevent the default behavior (selecting all)
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown); // Clean up the event listener
    };
  }, []);

  useEffect(() => {
    if (!applicationId && !applicationStatus?.applicationId) {
      return;
    }
    const id = applicationId || applicationStatus?.applicationId;
    dispatch(
      getNextQuestion({
        applicationId: id,
        data: {},
        navigate
      })
    );
  }, [applicationStatus?.applicationId, applicationId]);

  useEffect(() => {
    if (eventQuestion?.isLastSectionQuestion) {
      const id = applicationId || applicationStatus?.applicationId;
      dispatch(getSectionInstructions(id));
    }
  }, [eventQuestion?.isLastSectionQuestion]);

  useEffect(() => {
    const preventEvent = event => {
      // here event that candidate tried to paste text could be fired
      event.preventDefault();
    };

    document.addEventListener('copy', preventEvent);
    document.addEventListener('paste', preventEvent);
    document.addEventListener('contextmenu', preventEvent);
    document.addEventListener('drop', preventEvent);
    document.addEventListener('fullscreenchange', sendFullScreenModeEvent);
    window.addEventListener('blur', sendMouseInAssessmentEvent);

    return () => {
      document.removeEventListener('copy', preventEvent);
      document.removeEventListener('paste', preventEvent);
      document.removeEventListener('contextmenu', preventEvent);
      document.addEventListener('drop', preventEvent);
      document.removeEventListener('fullscreenchange', sendFullScreenModeEvent);
      window.removeEventListener('blur', sendMouseInAssessmentEvent);
    };
  }, []);

  const onCompliteQuestionHandler = essay => {
    const { testId, sectionId, questionId } = eventQuestion;
    const id = applicationId || applicationStatus?.applicationId;
    dispatch(
      getNextQuestion({
        applicationId: id,
        data: {
          testId,
          sectionId,
          questionId,
          answer: eventQuestion?.type === 'essay' ? [essay.essay] : selectedAnswer
        },
        navigate
      })
    );
    dispatch(setSelectedAnswer([]));

    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      /* Safari */
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
      /* IE11 */
      document.documentElement.msRequestFullscreen();
    }
  };

  const selectAnswerHandler = id => {
    if (eventQuestion?.type === 'single') {
      isSelectedAnswer(id) ? dispatch(setSelectedAnswer([])) : dispatch(setSelectedAnswer([id]));
    } else {
      isSelectedAnswer(id)
        ? dispatch(setSelectedAnswer(selectedAnswer.filter(items => items !== id)))
        : dispatch(setSelectedAnswer([...selectedAnswer, id]));
    }
  };

  return (
    <section
      className={`flex ${
        isAllAttachments ? 'md:min-w-[800px]' : 'md:w-[800px]'
      } mx-[8px] flex-col justify-center gap-6 overflow-hidden rounded-xl border border-gray-400 bg-white py-6 px-[12px] shadow-med sm:mx-[20px] md:px-10`}>
      <HtmlTextReplacer
        style={{
          wordBreak: 'break-word',
          maxWidth:
            isAllAttachments && eventQuestion?.answers?.length > 0
              ? `${eventQuestion?.answers?.length * 180}px`
              : '800px'
        }}
        className="break-words text-lg"
        html={eventQuestion?.text}
      />
      {eventQuestion?.attachment?.url && (
        <div className="flex w-full items-center justify-center">
          <Attachment attachment={eventQuestion?.attachment} maxWidth={520} />
        </div>
      )}
      {eventQuestion?.type === 'essay' ? (
        <Form
          id="essayAnswer"
          onSubmit={value => onCompliteQuestionHandler(value)}
          className={`flex-1 ${eventQuestion?.attachment?.url && 'pt-10'}`}>
          <Textarea
            name="essay"
            maxLength={eventQuestion?.essayLimit}
            onChangeValue={e =>
              setEssayText(prevText => {
                const prevLength = prevText.replace(/ /g, '').length;
                const curLength = e.replace(/ /g, '').length;
                if (curLength > prevLength + 1) {
                  // new value differs from the previous one more that for one symbol, so send an event that user did a paste
                  sendPasteEvent();
                }
                return e;
              })
            }
          />
        </Form>
      ) : (
        <div className="flex flex-1 flex-col gap-3">
          <Typography
            variant="body-regular"
            title={`Choose ${
              eventQuestion?.type === 'single' ? 'one correct option' : 'a few correct options'
            }:`}
            className="text-[#6D7175]"
          />
          <List
            data={
              eventQuestion?.isShuffle ? shuffle(eventQuestion?.answers) : eventQuestion?.answers
            }
            element={({ text, id, attachment }) => (
              <SelectAnswer
                _id={id}
                handleChange={selectAnswerHandler}
                label={text}
                attachment={attachment}
                questionType={eventQuestion?.type}
                selectedAnswer={selectedAnswer}
                isAllAttachments={isAllAttachments}
              />
            )}
            keyExtractor={(_, idx) => idx}
            wrap="div"
            className={`flex flex-1 justify-center md:justify-start ${
              !isAllAttachments && 'max-w-[800px] flex-col'
            } flex-wrap gap-3`}
          />
        </div>
      )}
      <div className="flex items-center justify-center">
        {eventQuestion?.type !== 'essay' ? (
          <Button
            className="flex w-full justify-center border-none text-lg disabled:border-none md:max-w-max"
            disabled={selectedAnswer.length === 0}
            variant="primary"
            onClick={() => onCompliteQuestionHandler()}
            title={eventQuestion?.isLastQuestion ? 'Finish test' : 'Next question'}
          />
        ) : (
          <Button
            form="essayAnswer"
            className="flex w-full justify-center text-lg disabled:border-none md:max-w-max"
            variant="primary"
            type="submit"
            title={eventQuestion?.isLastQuestion ? 'Finish test' : 'Next question'}
          />
        )}
      </div>
    </section>
  );
};
