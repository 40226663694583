import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as routes from 'routes/routes';
import { useLocation } from 'react-router-dom';
import { HtmlTextReplacer } from 'components/HtmlTextReplacer';
import { localStorageService } from 'helpers/localStorageService';
import Button from 'ui-kit/Button';
import Typography from 'ui-kit/Typography';
import TimeBadget from 'components/TimeBadget';
import useAppSelector from 'store/hooks/useAppSelector';

export const CandidateInvite = () => {
  const [introStep, setIntroStep] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { assessmentStatus } = useAppSelector(store => store.event);

  const navigateToSignUp = () => {
    const { search, hash } = location;
    const url = `${search}${hash}`;
    navigate(routes.CANDIDATE_SIGN_UP + url);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [introStep]);

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorageService.clearStorage();
  }, []);

  return introStep === 0 ? (
    <section className="mx-[8px] mb-8 flex max-w-[664px] flex-col gap-4 rounded-lg border border-[#C9CCCF] bg-white py-6 px-[12px] shadow-med sm:mx-[20px] md:px-12">
      <Typography className="max-w-[400px] self-center text-center" variant="heading-h2">
        {assessmentStatus?.companyName} invites you to take the {assessmentStatus?.name}
      </Typography>
      <p className="text-center text-2xl font-semibold"></p>
      <div className="flex flex-col gap-4 text-base">
        <p className="flex flex-wrap gap-2 break-words">
          It will take approximately <TimeBadget time={assessmentStatus?.timeLimit} showIcon /> to
          complete.
        </p>
        <p className="flex flex-wrap gap-2 break-words">
          The full assessment consist of
          <span
            className={`flex h-[22px] items-center gap-[4.5px] rounded border-[0.5px] border-[#CBD6D6] bg-[#EEF2F2] py-[1px] px-[6px] text-sm font-semibold text-[#05443D]
            shadow-small`}>
            {assessmentStatus?.testsCount} tests
          </span>
          and
          <span
            className={`flex h-[22px] items-center gap-[4.5px] rounded border-[0.5px] border-[#CBD6D6] bg-[#EEF2F2] py-[1px] px-[6px] text-sm font-semibold text-[#05443D]
            shadow-small`}>
            {assessmentStatus?.customQuestionsCount} custom questions
          </span>
        </p>
      </div>
      <p>The assessment is timed. A timer is shown per test or per question.</p>
      <p>You are free to use a calculator, pen and paper.</p>
      <p>We recommend completing the assessment in one go.</p>
      <p>Before beginning the test, we kindly recommend you to:</p>
      <ul className="list-none pl-2">
        <li>
          <span className="pr-1">&bull;</span> Find a quiet place with no distractions.
        </li>
        <li>
          <span className="pr-1">&bull;</span> Turn on Do not Disturb mode.
        </li>
        <li>
          <span className="pr-1">&bull;</span> Read the instructions and questions carefully.
        </li>
        <li>
          <span className="pr-1">&bull;</span> Stay calm and focused.
        </li>
        <li>
          <span className="pr-1">&bull;</span> Keep a close eye on the timer.
        </li>
      </ul>
      <Button
        variant="primary"
        onClick={() => {
          setIntroStep(1);
        }}
        title="Next"
        className="m-auto mt-4 w-full sm:max-w-max"
      />
    </section>
  ) : (
    <section className="mx-[20px] mb-8 flex max-w-[664px] flex-col gap-4 rounded-lg border border-gray-300 bg-white py-6 px-[18px] shadow-med md:mx-6 md:px-12">
      <Typography
        className="md:text-center"
        variant="heading-h2"
        title={`A few words from ${assessmentStatus?.companyName}`}
      />
      <HtmlTextReplacer html={assessmentStatus?.description} />
      <Button
        variant="primary"
        onClick={navigateToSignUp}
        title="Next"
        className="m-auto mt-4 w-full sm:max-w-max"
      />
    </section>
  );
};
