import { ResultWidget } from 'components/ResultWidget';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import Typography from 'ui-kit/Typography';
import { getModifyDataWithYear } from 'utils/helpers';

const calculateColor = percent => {
  if (percent > 80) {
    return { background: '#F1F8F5', border: '#2F6E63' };
  } else if (percent > 40) {
    return { background: '#FFF5EA', border: '#DB8912' };
  } else {
    return { background: '#FFF4F4', border: '#E3714F' };
  }
};

export const ResultsOveralCertificate = ({ tests, completedAt }) => (
  <div className="flex flex-col items-center justify-center">
    <div className="mt-[72px] flex flex-wrap items-center justify-center gap-[72px]">
      {tests
        ?.filter(
          item => !item.chartSettings?.chartStyle || item.chartSettings?.chartStyle === 'none'
        )
        .map(({ result, scaledResult, resultInterpretation, _id, name }) => (
          <div key={_id} className="flex flex-col items-center gap-6">
            <Typography variant="heading-h5" title={name} />
            <div className="flex h-[110px] w-[110px]">
              <CircularProgressbarWithChildren
                value={(scaledResult ?? result)?.replace(/[^0-9\.]/g, '')}
                background
                styles={buildStyles({
                  backgroundColor: calculateColor(
                    (scaledResult ?? result)?.replace(/[^0-9\.]/g, '')
                  ).background,
                  pathColor: calculateColor((scaledResult ?? result)?.replace(/[^0-9\.]/g, ''))
                    .border
                })}>
                <p>{resultInterpretation?.title}</p>
                <p>{scaledResult ?? result}</p>
              </CircularProgressbarWithChildren>
            </div>
          </div>
        ))}
    </div>
    <div className="my-[48px] flex w-full flex-wrap items-center justify-center gap-[72px]">
      {tests
        ?.filter(
          item => item.chartSettings?.chartStyle && item.chartSettings?.chartStyle !== 'none'
        )
        .map(test => {
          return (
            <div key={test._id} className="flex flex-col items-center gap-6">
              <Typography variant="heading-h5" title={test.name} />
              <div className="h-auto w-full items-center gap-6 md:h-[250px] md:w-[600px] lg:h-[250px] lg:w-[600px]">
                <ResultWidget {...test} />
              </div>
            </div>
          );
        })}
    </div>
    <div className="mb-[24px] flex items-center justify-center">
      <Typography
        variant="small"
        title={`These tests were passed on ${getModifyDataWithYear(completedAt)}`}
        className="text-[#8C9196]"
      />
    </div>
  </div>
);
