import CertificateCandidates from 'assets/CertificateCandidates.svg';
import CertificateCandidates2 from 'assets/CertificateCandidates2.svg';
import copyToClipboard from 'utils/copyToClipboard';
import { callToast } from 'utils/helpers';
import { useCallback, useState } from 'react';
import Lottie from 'react-lottie';
import arrowLoading from 'assets/lotties/arrow-down-loading.json';
import Typography from 'ui-kit/Typography';
import Button from 'ui-kit/Button';

export const InfoCertificate = ({ copyLink }) => {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const copyHandle = useCallback(
    text => () => {
      copyToClipboard(text);
      callToast('success', 'Success - link was copy to clipboard');
    },
    []
  );
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: arrowLoading,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return window.innerWidth < 1100 ? (
    <div className="flex flex-col gap-6 bg-[#FAFBFB] px-[50px] pt-[48px] pb-[25px]">
      <Typography variant="heading-h1" title="Share your Hirenest Certificate to get job faster" />
      <div>
        The Hirenest test certificate helps people find work more easily.
        <br />
        <br />
        Employers appreciate the quality of hirenest tests and trust them.
      </div>
      <div onClick={copyHandle(copyLink)} className="flex justify-between">
        <input
          value={copyLink}
          disabled
          className="flex-1 overflow-hidden rounded-l-md border border-gray-400 bg-white p-2.5 text-[#6D7175]"
        />
        <Button variant="input-outline" title="Copy Link" />
      </div>
      <div className="mt-[20px] flex flex-col">
        <Typography className="text-center" variant="heading-h4" title="Benefits of Certificate" />
      </div>
      <div className="flex flex-wrap items-end justify-between gap-[48px]">
        <div className="flex flex-1 flex-col items-center gap-[8px]">
          <div className="flex w-[110px] items-end justify-center gap-[8px] border border-b-[#C9C9C9]">
            <div className="h-[60px] w-[32px] rounded-t-md bg-[#E2E2E2]" />
            <div className="h-[130px] w-[32px] rounded-t-md bg-[#007D6E]" />
          </div>
          <p className="text-2xl font-semibold text-green-400">x2</p>
          <p className="text-base text-[#263238]">Satisfaction</p>
        </div>
        <div className="flex flex-1 flex-col items-center gap-[8px]">
          <div className="flex w-[110px] items-end justify-center gap-[8px] border border-b-[#C9C9C9]">
            <div className="h-[36px] w-[32px] rounded-t-md bg-[#E2E2E2]" />
            <div className="h-[77px] w-[32px] rounded-t-md bg-[#007D6E]" />
          </div>
          <p className="text-2xl font-semibold text-green-400">$13,000+</p>
          <p className="truncate text-base text-[#263238]">Potentialy bigger early salary</p>
        </div>
        <div className="flex flex-1 flex-col items-center gap-[8px]">
          <div className="flex w-[110px] items-end justify-center gap-[8px] border border-b-[#C9C9C9]">
            <div className="h-[83px] w-[32px] rounded-t-md bg-[#E2E2E2]" />
            <div className="h-[41px] w-[32px] rounded-t-md bg-[#007D6E]" />
          </div>
          <p className="text-2xl font-semibold text-green-400">140 hours</p>
          <p className="text-base text-[#263238]">Potentialy saved time</p>
        </div>
      </div>
      <div className="mt-[10px] flex items-center justify-center gap-1 sm:gap-3">
        <p className="border-b border-dashed border-black pb-1 text-sm sm:text-2xl">
          Why do I need this certificate?
        </p>
        <Button
          onClick={() => setDropDownOpen(prev => !prev)}
          className="flex"
          iconSize={18}
          icon={dropDownOpen ? 'arrow-up' : 'arrow-down'}
        />
      </div>
      {dropDownOpen && (
        <div className="flex flex-1 flex-col gap-3">
          <div className="flex gap-[48px]">
            <div className="flex flex-1 flex-col items-center gap-3 md:flex-row">
              <img className="max-h-[100px] max-w-[100px]" src={CertificateCandidates} />
              <p className="text-center text-sm">
                Only 4-6 out of 250 candidates will be interviewed.
              </p>
            </div>
            <div className="flex flex-1 flex-col items-center gap-3 md:flex-row">
              <img className="max-h-[100px] max-w-[100px]" src={CertificateCandidates2} />
              <p className="text-center text-sm">
                Stand out of the crowd of candidates with Hirenest PEA Certificate
              </p>
            </div>
          </div>
          <Typography
            className="mt-[20px] text-center"
            variant="heading-h4"
            title="How to use it?"
          />
          <div className="mt-[12px] flex flex-col gap-6">
            <div className="flex justify-between gap-6">
              <div className="flex w-[183px] flex-col items-center gap-[6px]">
                <Typography variant="heading-h5" title="LinkedIn" className="text-[#007D6F]" />
                <Typography
                  className="text-center"
                  variant="small"
                  title="Catch researchers attention"
                />
              </div>
              <div className="flex w-[183px] flex-col items-center gap-[6px]">
                <Typography
                  variant="heading-h5"
                  title="Application letter"
                  className="text-[#007D6F]"
                />
                <Typography
                  className="text-center"
                  variant="small"
                  title="Include a certificate link"
                />
              </div>
              <div className="flex w-[183px] flex-col items-center gap-[6px]">
                <Typography variant="heading-h5" title="Resume" className="text-[#007D6F]" />
                <Typography
                  className="text-center"
                  variant="small"
                  title="Add a certificate link to your resume"
                />
              </div>
            </div>
            <div className="flex justify-between gap-6">
              <div className="flex w-[183px] flex-col items-center gap-[6px]">
                <Typography variant="heading-h5" title="Price" className="text-[#007D6F]" />
                <p className="font-semibold">$0.00</p>
                <Typography
                  className="text-center"
                  variant="small"
                  title="HN PEA Certificates are priced at $199, but for the top 20% of candidates, the certificate is free"
                />
              </div>
              <div className="flex w-[183px] flex-col items-center gap-[6px]">
                <Typography variant="heading-h5" title="Expiration" className="text-[#007D6F]" />
                <p className="text-center font-semibold">December 3,2023</p>
              </div>
              <div className="flex w-[183px] flex-col items-center gap-[6px]">
                <Typography variant="heading-h5" title="Extendable" className="text-[#007D6F]" />
                <Typography
                  className="text-center"
                  variant="small"
                  title="With additional coursework and measures taken during the expiration period"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <Typography
        className="mt-[10px] text-center text-[#6D7175]"
        variant="small"
        title="A block above is visible only for you. A recruiter will see only a certificate below."
      />
      <Lottie options={defaultOptions} height={32} width={32} />
    </div>
  ) : (
    <div className="flex flex-col gap-[22px] bg-[#FAFBFB] px-[50px] pt-[48px] pb-[25px]">
      <div className="flex gap-[48px]">
        <div className="flex flex-1 flex-col gap-6">
          <Typography
            variant="heading-h1"
            title="Share your Hirenest Certificate to get job faster"
          />
          <div>
            The Hirenest test certificate helps people find work more easily.
            <br />
            <br />
            Employers appreciate the quality of hirenest tests and trust them.
          </div>
          <div onClick={copyHandle(copyLink)} className="flex justify-between">
            <input
              value={copyLink}
              disabled
              className="flex-1 overflow-hidden rounded-l-md border border-gray-400 bg-white p-2.5 text-[#6D7175]"
            />
            <Button variant="input-outline" title="Copy Link" />
          </div>
          <div className="flex flex-col">
            <Typography
              className="text-center"
              variant="heading-h4"
              title="Benefits of Certificate"
            />
          </div>
          <div className="flex items-end justify-between">
            <div className="flex flex-col items-center gap-[8px]">
              <div className="flex w-[110px] items-end justify-center gap-[8px] border border-b-[#C9C9C9]">
                <div className="h-[60px] w-[32px] rounded-t-md bg-[#E2E2E2]" />
                <div className="h-[130px] w-[32px] rounded-t-md bg-[#007D6E]" />
              </div>
              <p className="text-2xl font-semibold text-green-400">x2</p>
              <p className="text-base text-[#263238]">Satisfaction</p>
            </div>
            <div className="flex flex-col items-center gap-[8px]">
              <div className="flex w-[110px] items-end justify-center gap-[8px] border border-b-[#C9C9C9]">
                <div className="h-[36px] w-[32px] rounded-t-md bg-[#E2E2E2]" />
                <div className="h-[77px] w-[32px] rounded-t-md bg-[#007D6E]" />
              </div>
              <p className="text-2xl font-semibold text-green-400">$13,000+</p>
              <p className="text-base text-[#263238]">Potentialy bigger early salary</p>
            </div>
            <div className="flex flex-col items-center gap-[8px]">
              <div className="flex w-[110px] items-end justify-center gap-[8px] border border-b-[#C9C9C9]">
                <div className="h-[83px] w-[32px] rounded-t-md bg-[#E2E2E2]" />
                <div className="h-[41px] w-[32px] rounded-t-md bg-[#007D6E]" />
              </div>
              <p className="text-2xl font-semibold text-green-400">140 hours</p>
              <p className="text-base text-[#263238]">Potentialy saved time</p>
            </div>
          </div>
        </div>
        <div className="h-full w-[2px] bg-[#D2D5D8]" />
        <div className="flex flex-1 flex-col gap-3">
          <Typography
            className="text-center"
            variant="heading-h4"
            title="Why do I need this certificate?"
          />
          <div className="flex gap-[48px]">
            <div className="flex flex-1 items-center gap-3">
              <img src={CertificateCandidates} />
              <p className="text-sm">Only 4-6 out of 250 candidates will be interviewed.</p>
            </div>
            <div className="flex flex-1 items-center gap-3">
              <img src={CertificateCandidates2} />
              <p className="text-sm">
                Stand out of the crowd of candidates with Hirenest PEA Certificate
              </p>
            </div>
          </div>
          <Typography
            className="mt-[20px] text-center"
            variant="heading-h4"
            title="How to use it?"
          />
          <div className="mt-[12px] flex flex-col gap-6">
            <div className="flex justify-between gap-6">
              <div className="flex w-[183px] flex-col gap-[6px]">
                <Typography variant="heading-h5" title="LinkedIn" className="text-[#007D6F]" />
                <Typography variant="small" title="Catch researchers attention" />
              </div>
              <div className="flex w-[183px] flex-col gap-[6px]">
                <Typography
                  variant="heading-h5"
                  title="Application letter"
                  className="text-[#007D6F]"
                />
                <Typography variant="small" title="Include a certificate link" />
              </div>
              <div className="flex w-[183px] flex-col gap-[6px]">
                <Typography variant="heading-h5" title="Resume" className="text-[#007D6F]" />
                <Typography variant="small" title="Add a certificate link to your resume" />
              </div>
            </div>
            <div className="flex justify-between gap-6">
              <div className="flex w-[183px] flex-col gap-[6px]">
                <Typography variant="heading-h5" title="Price" className="text-[#007D6F]" />
                <p className="font-semibold">$0.00</p>
                <Typography
                  variant="small"
                  title="HN PEA Certificates are priced at $199, but for the top 20% of candidates, the certificate is free"
                />
              </div>
              <div className="flex w-[183px] flex-col gap-[6px]">
                <Typography variant="heading-h5" title="Expiration" className="text-[#007D6F]" />
                <p className="font-semibold">December 3,2023</p>
              </div>
              <div className="flex w-[183px] flex-col gap-[6px]">
                <Typography variant="heading-h5" title="Extendable" className="text-[#007D6F]" />
                <Typography
                  variant="small"
                  title="With additional coursework and measures taken during the expiration period"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Typography
        className="text-center text-[#6D7175]"
        variant="small"
        title="A block above is visible only for you. A recruiter will see only a certificate below."
      />
      <Lottie options={defaultOptions} height={32} width={32} />
    </div>
  );
};
