import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as routes from 'routes/routes';
import useAppSelector from 'store/hooks/useAppSelector';
import Button from 'ui-kit/Button';
import TextAreaView from 'ui-kit/TextAreaView';
import Typography from 'ui-kit/Typography';

export const SectionIntro = () => {
  const navigate = useNavigate();
  const { sectionInstructions, sectionName } = useAppSelector(store => store.event);

  useEffect(() => {
    if (!sectionInstructions) {
      navigate(routes.CANDIDATE_QUESTION);
    }
    window.scrollTo(0, 0);
  }, [sectionInstructions]);
  return (
    <section className="mx-[8px] w-[830px] rounded-lg border border-gray-300 bg-white py-6 px-[12px] shadow-med sm:mx-[20px] md:px-12">
      <div className="flex w-full flex-col space-y-6">
        <Typography variant="heading-h2" className="capitalize" title={sectionName} />
        <TextAreaView variant="body-regular" title={sectionInstructions} />
        <Button
          variant="primary"
          onClick={() => navigate(routes.CANDIDATE_QUESTION)}
          title="Got it"
          className="m-auto mt-4 w-full md:max-w-max"
        />
      </div>
    </section>
  );
};
