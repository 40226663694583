import { Tooltip } from 'react-tooltip';
import { charDataCalculate } from './ResultsDetailsCertificate';
import Icon from 'ui-kit/Icon';

export const ResultDetailsTestSection = ({
  sectionName,
  resultInterpretation,
  chartSettings,
  calculatedResult,
  sectionDescription,
  sections,
  result,
  idx
}) => (
  <>
    <div className="flex flex-wrap gap-2">
      <div className="flex items-center gap-[8px]">
        <div
          className="h-[10px] min-h-[10px] w-[10px] min-w-[10px] rounded-full"
          style={{
            background: charDataCalculate(sections, chartSettings, result, true)?.datasets[0]
              ?.backgroundColor?.[chartSettings?.includeTestOverall ? idx + 1 : idx]
          }}
        />
        <p className="text-lg font-medium">{sectionName}</p>
      </div>
      {sectionDescription && (
        <Tooltip text={sectionDescription}>
          <Icon className="h-[16px] w-[16px]" icon="Question" />
        </Tooltip>
      )}
      {resultInterpretation?.title && (
        <div className="rounded-md border-[#999EA4] bg-[#999EA4] px-[8px] py-[4px] text-white">{`${calculatedResult} ${resultInterpretation?.title}`}</div>
      )}
    </div>
    <p className="text-base">{resultInterpretation?.description}</p>
  </>
);
