import Loader from 'components/Loader';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAppDispatch from 'store/hooks/useAppDispatch';
import { verifyApplication } from 'store/thunks/applicationThunks';

export const CandidateVerify = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { hash } = useLocation();

  useEffect(() => {
    if (!hash) {
      return;
    }
    const preparedHash = hash.substring(1);
    dispatch(
      verifyApplication({
        data: { hash: preparedHash },
        navigate
      })
    );
  }, [hash]);
  return <Loader />;
};
