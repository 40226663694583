import React from 'react';
import Icon from '../Icon';
import { variants, sizes } from './variants';
import { twMerge } from 'tailwind-merge';

interface ButtonProps {
  variant?: keyof typeof variants;
  rounded?: boolean;
  iconStart?: any;
  icon?: any;
  size?: keyof typeof sizes;
  buttonText?: string | React.ReactNode;
  children?: React.ReactNode;
  iconSize?: number | string;
  disabled?: boolean;
  iconEnd?: any;
  [key: string]: any;
}

const Button: React.FC<ButtonProps> = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant,
      iconStart,
      className,
      title,
      icon,
      disabled,
      onClick,
      size = 'medium',
      children,
      iconSize,
      iconEnd,
      ...rest
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        className={twMerge(size && sizes[size], variant && variants[variant], className)}
        disabled={disabled}
        onClick={onClick}
        {...rest}>
        {iconStart && <Icon icon={iconStart} size={iconSize} />}
        {title ?? children ?? <Icon icon={icon} size={iconSize} />}
        {iconEnd && <Icon icon={iconEnd} size={iconSize} />}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
