import { createAsyncThunk } from '@reduxjs/toolkit';
import { applicationApi } from 'api/appApi';
import { localStorageService } from 'helpers/localStorageService';
import * as routes from 'routes/routes';

export const getApplicationData = createAsyncThunk(
  'application/getApplicationData',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await applicationApi.getApplicationData(payload.asssessmentId);
      return data;
    } catch (error: any) {
      if (error.response.status === 403) payload.navigate(routes.CANDIDATE_ASSESSMENT_FORBIDDEN);

      if (
        error.response.status === 403 ||
        error.response.status === 404 ||
        error.response.status === 422 ||
        error.response.status === 500
      )
        payload.navigate(routes.ERROR_PAGE + '/' + error.response.status);
      return rejectWithValue(error);
    }
  }
);

export const getApplicationStatus = createAsyncThunk(
  'application/getApplicationStatus',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await applicationApi.getApplicationStatusData(payload.data);
      if (data?.token) {
        localStorageService.setToken(data.token);
      }
      if (data?.applicationId) {
        localStorageService.setApplicationId(data.applicationId);
        localStorageService.setHash(data.hash);
      }
      if (data.applicationStatus === 'Started') {
        data.enableCamera
          ? payload.navigate(routes.CANDIDATE_CAMERA_SETUP)
          : payload.navigate(routes.CANDIDATE_ROADMAP);
      }
      if (data?.completedAt) {
        payload.navigate(routes.CANDIDATE_ASSESSMENT_TWICE);
      }
      return data;
    } catch (error: any) {
      if (
        error.response.status === 403 ||
        error.response.status === 404 ||
        error.response.status === 422 ||
        error.response.status === 500
      )
        payload.navigate(routes.ERROR_PAGE + '/' + error.response.status);
      return rejectWithValue(error);
    }
  }
);

export const verifyApplication = createAsyncThunk(
  'application/verifyApplication',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await applicationApi.verifyApplication(payload.data);
      if (data?.completedAt) {
        payload.navigate(routes.CANDIDATE_ASSESSMENT_TWICE);
      }
      if (data?.applicationId) {
        localStorageService.setToken(data.token);
        localStorageService.setApplicationId(data.applicationId);
        localStorageService.setRole(data.user.role);
        localStorageService.setHash(data.hash);
        data.enableCamera
          ? payload.navigate(routes.CANDIDATE_CAMERA_SETUP)
          : payload.navigate(routes.CANDIDATE_ROADMAP);
      }
      return data;
    } catch (error: any) {
      if (
        error.response.status === 403 ||
        error.response.status === 404 ||
        error.response.status === 422 ||
        error.response.status === 500
      )
        payload.navigate(routes.ERROR_PAGE + '/' + error.response.status);
      return rejectWithValue(error);
    }
  }
);

export const getAssessmentStatus = createAsyncThunk(
  'assessment/getAssessmentStatus',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await applicationApi.getAssessmentStatusData(payload.data);
      return data;
    } catch (error: any) {
      if (
        error.response.status === 403 ||
        error.response.status === 404 ||
        error.response.status === 422 ||
        error.response.status === 500
      ) {
        payload.navigate(routes.ERROR_PAGE + '/' + error.response.status);
      }
      return rejectWithValue(error);
    }
  }
);

export const getRoadmapStatus = createAsyncThunk(
  'roadmap/getRoadmapStatus',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await applicationApi.getRoadmapStatus(payload.id);
      if (data?.status) {
        const response = await applicationApi.getRoadmap(payload.id);
        return response.data;
      } else {
        payload.navigate(routes.CANDIDATE_QUESTION);
      }
    } catch (error: any) {
      if (
        error.response.status === 403 ||
        error.response.status === 404 ||
        error.response.status === 422 ||
        error.response.status === 500
      )
        payload.navigate(routes.ERROR_PAGE + '/' + error.response.status);
      return rejectWithValue(error);
    }
  }
);

export const getSectionInstructions = createAsyncThunk(
  'section/getSectionInstructions',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await applicationApi.getSectionInstructions(payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCertificate = createAsyncThunk(
  'certificate/getCertificate',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await applicationApi.getCertificate(payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const getNextQuestion = createAsyncThunk(
  'question/getNextQuestion',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await applicationApi.getNextQuestion(payload);
      if (data?.testName && data?.testId) {
        localStorageService.setTestId(data.testId);
        localStorageService.setTestName(data.testName);
        localStorageService.setTestIndex(data.testIndex);
      }
      if (data?.showSectionInstructions) {
        payload.navigate(routes.CANDIDATE_SECTION_INSTRUCTIONS);
      }
      if (data?.finish) {
        payload.navigate(routes.CANDIDATE_APPLICATION_FEEDBACK);
      }
      if (data?.showRoadmap) {
        payload.navigate(routes.CANDIDATE_TEST_FEEDBACK);
      }
      return data;
    } catch (error: any) {
      if (
        error.response &&
        (error.response.status === 403 ||
          error.response.status === 404 ||
          error.response.status === 422 ||
          error.response.status === 500)
      ) {
        payload.navigate(routes.ERROR_PAGE + '/' + error.response.status);
      }
      return rejectWithValue(error);
    }
  }
);

export const antiCheatEvent = createAsyncThunk(
  'event/antiCheat',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await applicationApi.antiCheatEventFired(payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const sendScreenshot = createAsyncThunk(
  'event/sendScreenshot',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await applicationApi.sendScreenshot(payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const signInEvent = createAsyncThunk(
  'event/signInEvent',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await applicationApi.singInApplication(payload.data);
      window.localStorage.clear();
      window.sessionStorage.clear();
      if (data?.completedAt) {
        payload.navigate(routes.CANDIDATE_ASSESSMENT_TWICE);
        return { user: data };
      } else {
        if (data?.token) {
          localStorageService.setToken(data.token);
          localStorageService.setApplicationId(data.application);
          localStorageService.setRole(data.user.role);
          localStorageService.setHash(data.hash);
        }
        data.token
          ? payload.enableCamera
            ? payload.navigate(routes.CANDIDATE_CAMERA_SETUP)
            : payload.navigate(routes.CANDIDATE_ROADMAP)
          : payload.navigate(routes.CANDIDATE_EMAIL_CONFIRM);
        return data;
      }
    } catch (error: any) {
      if (error.response.status === 403) payload.navigate(routes.CANDIDATE_ASSESSMENT_FORBIDDEN);
      if (
        error.response.status === 404 ||
        error.response.status === 422 ||
        error.response.status === 500
      )
        payload.navigate(routes.ERROR_PAGE + '/' + error.response.status);
      return rejectWithValue(error);
    }
  }
);

export const testFeedback = createAsyncThunk(
  'feedback/testFeedback',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await applicationApi.testFeedback(payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getCountries = createAsyncThunk(
  'countries/countries',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await applicationApi.getCountries();
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const assessmentFeedback = createAsyncThunk(
  'feedback/assessmentFeedback',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await applicationApi.applicationFeedback(payload);
      if (data) {
        window.localStorage.clear();
        window.sessionStorage.clear();
      }
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);
