import axiosInstance from './axiosHelper';

export const applicationApi = {
  getApplicationStatusData(body: any) {
    return axiosInstance.post('candidate/application/status', body);
  },
  getAssessmentStatusData(body: any) {
    return axiosInstance.post('candidate/assessment/status', body);
  },
  singInApplication(body: any) {
    return axiosInstance.post('candidate/signup', body);
  },
  getCountries() {
    return axiosInstance.get('countries');
  },
  getRoadmapStatus(id: string) {
    return axiosInstance.get(`candidate/assessment/roadmap/${id}/status`);
  },
  getRoadmap(id: string) {
    return axiosInstance.get(`candidate/assessment/roadmap/${id}`);
  },
  getSectionInstructions(id: string) {
    return axiosInstance.get(`candidate/application/${id}/section-instructions`);
  },
  getApplicationData(id: string) {
    return axiosInstance.get(`candidate/assessment/${id}`);
  },
  verifyApplication(body: any) {
    return axiosInstance.post('candidate/verify', body);
  },
  getNextQuestion({ applicationId, data }: { applicationId: string, data: any }) {
    return axiosInstance.post(`candidate/application/${applicationId}/next-question`, data);
  },
  antiCheatEventFired({
    applicationId,
    data
  }: {
    applicationId: string,
    data: { param: 'pasteDetection' | 'mouseInAssessment' | 'fullScreenMode' }
  }) {
    return axiosInstance.post(`candidate/application/${applicationId}/anticheat`, data);
  },
  testFeedback({ applicationId, data }: { applicationId: string, data: any }) {
    return axiosInstance.post(`candidate/application/${applicationId}/test-feedback`, data);
  },
  applicationFeedback({ applicationId, data }: { applicationId: string, data: any }) {
    return axiosInstance.post(`candidate/application/${applicationId}/assessment-feedback`, data);
  },
  getCertificate(id: string) {
    return axiosInstance.get(`candidate/certificate/${id}`);
  },
  sendScreenshot({ file, id }: { file: any, id: string }) {
    return axiosInstance.post(`candidate/application/${id}/screenshot`, file);
  }
};
