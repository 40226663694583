import { stringToHslColor } from 'utils/stringToHslColor';
import { HtmlTextReplacer } from 'components/HtmlTextReplacer';
import { ResultDetailsTestSection } from './ResultDetailsTestSection';
import { ResultDetailsDimensionsSection } from './ResultDetailsDimensionsSection';
import { Tooltip } from 'react-tooltip';
import Icon from 'ui-kit/Icon';
import { ResultWidget } from 'components/ResultWidget';

export const charDataCalculate = (sections, chartSettings, result, withOverall = false) => {
  if (!chartSettings || !sections) {
    return;
  }
  const { chartStyle } = chartSettings;
  const data = [];
  const results = [];

  withOverall &&
    data.push('Test Overall') &&
    results.push(typeof result === 'string' ? +result?.replace(/[^0-9]/g, '') : result);

  sections.length > 1 &&
    sections.map(({ sectionName, calculatedResult }) => {
      data.push(sectionName);
      results.push(
        typeof calculatedResult === 'string'
          ? +calculatedResult?.replace(/[^0-9]/g, '')
          : calculatedResult
      );
    });

  return {
    labels: data,
    datasets: [
      {
        data: results,
        backgroundColor: data.map(value =>
          value === 'Test Overall'
            ? '#007D6E'
            : value && stringToHslColor(value, 75, 80, chartStyle === 'bar' ? 1 : 0.7)
        ),
        borderWidth: 1,
        minBarLength: 20,
        barThickness: 24
      }
    ]
  };
};

export const ResultsDetailsCertificate = ({ tests }) => (
  <div
    className="bg-cover bg-center bg-no-repeat px-[24px] py-[36px]"
    style={{
      backgroundImage:
        'linear-gradient(99.09deg, #F0FAF8 0%, rgba(234, 241, 214, 0.7) 51.13%, #FAE2DD 100%)'
    }}>
    <div className="flex flex-col gap-6 lg:px-[32px]">
      <p className="text-2xl font-semibold">Detailed Test Results</p>
      {tests?.map(
        ({
          _id,
          category,
          name,
          resultInterpretation,
          tagline,
          result,
          scaledResult,
          sections,
          chartSettings,
          minValue,
          maxValue,
          calculationType
        }) => (
          <div
            key={_id}
            className="flex rounded-md bg-[#FFFFFF]"
            style={{
              border: '1px solid rgba(23, 24, 24, 0.05)',
              boxShadow: '0px 1px 4px rgba(51, 51, 51, 0.16)'
            }}>
            <div className="flex w-full flex-col lg:flex-row">
              <div className="flex flex-col p-[20px] lg:w-[40%]">
                <p className="text-base font-semibold text-[#6D7175]">{category?.name}</p>
                <div className="flex flex-wrap items-center gap-2">
                  {chartSettings?.includeTestOverall && chartSettings?.chartStyle !== 'none' && (
                    <div className="h-[10px] min-h-[10px] w-[10px] min-w-[10px] rounded-full bg-[#007D6E]" />
                  )}
                  <p className="text-lg font-medium lg:text-xl">{name}</p>
                  {tagline.length > 0 && (
                    <Tooltip text={tagline}>
                      <Icon className="h-[16px] w-[16px]" icon="Question" />
                    </Tooltip>
                  )}
                  {(scaledResult || result) && (
                    <div className="rounded-md border-[#999EA4] bg-[#999EA4] px-[8px] py-[4px] text-white">{`${
                      scaledResult ?? result
                    } ${resultInterpretation?.title || ''}`}</div>
                  )}
                </div>
                <HtmlTextReplacer
                  className="mt-[12px] text-base"
                  html={resultInterpretation?.description}
                />
                {chartSettings?.chartStyle !== 'none' &&
                  (chartSettings?.chartStyle === 'polar' ||
                    chartSettings?.chartStyle === 'bar') && (
                    <div className="mt-[10px] flex h-auto flex-1 justify-center md:h-[250px]">
                      <ResultWidget
                        chartSettings={chartSettings}
                        sections={sections}
                        scaledResult={scaledResult}
                        result={result}
                        calculationType={calculationType}
                        minValue={minValue}
                        maxValue={maxValue}
                      />
                    </div>
                  )}
              </div>
              {sections?.length > 1 && (
                <div
                  className={`flex flex-col gap-6 lg:flex-1
                    ${chartSettings?.chartStyle !== 'dimensions' ? 'bg-[#F6F6F7] p-[20px]' : 'mb-6'}
                  `}>
                  {sections?.map((section, idx) => (
                    <div key={_id} className="flex flex-col gap-3">
                      {chartSettings?.chartStyle !== 'dimensions' ? (
                        <ResultDetailsTestSection
                          {...section}
                          chartSettings={chartSettings}
                          sections={sections}
                          result={result}
                          idx={idx}
                        />
                      ) : (
                        <ResultDetailsDimensionsSection {...section} />
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )
      )}
    </div>
  </div>
);
