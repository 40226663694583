import React from 'react';

import { useController } from 'react-hook-form';
import Typography from 'ui-kit/Typography';

interface ErrorMessageProps {
  name: string;
  component?({ message, isValid }: { message: string; isValid: boolean }): string;
  maxLength?: number;
  className?: string;
  successMessage?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  name,
  component,
  maxLength,
  className,
  successMessage = ''
}) => {
  const { field, formState } = useController({ name, rules: { maxLength, max: 5 } });

  const { message = '' } = (formState.errors?.[name] as { message?: string }) || {};
  const { isValid } = formState;

  const infoMessage = isValid ? successMessage : message;

  if (component) return <>{component({ message: infoMessage, isValid })}</>;

  return (
    <div className={`flex min-h-[21px] justify-between gap-2 ${className}`}>
      {infoMessage && <Typography variant='label-default' className={isValid ? "text-green-600" : "text-red-600"} title={infoMessage} />}
      {maxLength && (
        <Typography
          className="ml-auto"
          variant="label-default"
          title={`${field.value.length}/${maxLength}`}
        />
      )}
    </div>
  );
};

export default ErrorMessage;
