import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  assessmentFeedback,
  getApplicationData,
  getApplicationStatus,
  getAssessmentStatus,
  getCertificate,
  getCountries,
  getNextQuestion,
  getRoadmapStatus,
  getSectionInstructions,
  signInEvent,
  testFeedback,
  verifyApplication,
  antiCheatEvent
} from 'store/thunks/applicationThunks';

interface State {
  loading: boolean;
  applicationStatus: Record<string, unknown>;
  assessmentStatus: Record<string, unknown>;
  roadmap: unknown[];
  currentTest: Record<string, unknown>;
  eventQuestion: Record<string, unknown>;
  sectionInstructions: string;
  sectionName: string;
  selectedAnswer: unknown[];
  countries: any;
  eventData: any;
  candidate: null | Record<string, unknown>;
  applicationId: null | string;
  asssessmentId: null | string;
  error: null | Record<string, unknown>;
  feedback: Record<string, unknown>;
  certificate: Record<string, unknown>;
  reactions: unknown[];
}

const initialState: State = {
  loading: false,
  applicationStatus: {},
  assessmentStatus: {},
  roadmap: [],
  currentTest: {},
  eventQuestion: {},
  eventData: {},
  sectionInstructions: '',
  countries: [],
  sectionName: '',
  selectedAnswer: [],
  candidate: null,
  applicationId: null,
  asssessmentId: null,
  error: null,
  feedback: {},
  certificate: {},
  reactions: []
};

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    resetErrors: state => {
      state.error = null;
    },
    setFeedbackUserdata: (state, action) => {
      state.feedback = { ...state.feedback, ...action.payload };
    },
    setSelectedAnswer: (state, action) => {
      state.selectedAnswer = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getApplicationData.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = null;
      state.eventData = action.payload;
    });
    builder.addCase(getCertificate.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = null;
      state.certificate = action.payload;
    });
    builder.addCase(getSectionInstructions.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = null;
      state.sectionInstructions = action.payload.instructions;
      state.sectionName = action.payload.name;
    });
    builder.addCase(getSectionInstructions.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.sectionInstructions = '';
    });
    builder.addCase(getApplicationStatus.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = null;
      state.applicationStatus = action.payload;
    });
    builder.addCase(getCountries.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = null;
      state.countries = action.payload;
    });
    builder.addCase(antiCheatEvent.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(verifyApplication.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = null;
      state.applicationStatus = action.payload;
    });
    builder.addCase(getAssessmentStatus.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = null;
      state.assessmentStatus = action.payload;
    });
    builder.addCase(getRoadmapStatus.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = null;
      state.roadmap = action.payload.roadmap;
      state.currentTest = action.payload.currentTest;
    });
    builder.addCase(getNextQuestion.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = null;
      state.eventQuestion = action.payload;
    });
    builder.addCase(signInEvent.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = null;
      state.candidate = action.payload.user;
      state.applicationId = action.payload.application;
    });
    builder.addCase(testFeedback.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(assessmentFeedback.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = null;
    });
    builder.addMatcher(
      action => {
        if (action.type === 'event/sendScreenshot/pending') {
          return false;
        }
        return action.type.endsWith('/pending') || action.type.endsWith('/rejected');
      },
      (state, action) => {
        state.loading = action.type.endsWith('/pending');
        state.error = action.type.endsWith('/rejected') ? action.payload : null;
      }
    );
  }
});

export const { resetErrors, setFeedbackUserdata, setSelectedAnswer } = eventSlice.actions;

export default eventSlice.reducer;
