import complete from 'assets/image/complete.svg';
import Typography from 'ui-kit/Typography';

export const CandidateCompleted = () => {
  return (
    <section className="mx-[8px] flex flex-col items-center rounded-xl border border-gray-400 bg-white py-6 px-[12px] shadow-med sm:mx-[20px] md:px-16">
      <Typography variant="heading-h3" title="See you, have a nice day! " className="mb-5" />
      <Typography variant="body-regular" className="text-center text-lg">
        Employer will review your application, usually that takes a day or two.
        <br /> Check your email for an update.
      </Typography>
      <img src={complete} alt="complete" className="mx-auto w-full sm:max-w-sm" />
      <a href="https://hirenest.com/" target="_blank" rel="noreferrer">
        <Typography
          variant="body-bold"
          title="Learn more about Hirenest"
          className="text-green-400"
        />
      </a>
    </section>
  );
};
