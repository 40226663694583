export interface TypographyVariant {
  className: string;
  tag: keyof JSX.IntrinsicElements;
}

export const variants: Record<string, TypographyVariant> = {
  'heading-h1': {
    className: 'font-figtree text-h1 font-semibold leading-[130%] tracking-[-0.5px]',
    tag: 'h1'
  },
  'heading-h2': {
    className: 'font-figtree text-h2 font-medium leading-[130%] tracking-[-0.3px]',
    tag: 'h2'
  },
  'heading-h3': {
    className: 'font-figtree text-h3 font-medium leading-[130%] tracking-[-0.2px]',
    tag: 'h3'
  },
  'heading-h4': {
    className: 'font-figtree text-h4 font-medium leading-[140%] tracking-[-0.1px]',
    tag: 'h4'
  },
  'heading-h5': {
    className: 'font-figtree text-h5 font-medium leading-[150%]',
    tag: 'h5'
  },
  'heading-h6': {
    className: 'font-figtree text-h6 font-medium leading-[150%]',
    tag: 'h6'
  },
  'large-default': {
    className: 'font-figtree text-lg font-normal leading-[150%]',
    tag: 'p'
  },
  'regular-default': {
    className: 'font-figtree text-base font-normal leading-[150%]',
    tag: 'p'
  },
  'regular-emphasis': {
    className: 'font-figtree text-base font-semibold leading-[150%]',
    tag: 'p'
  },
  'regular-underline': {
    className: 'font-figtree text-base font-normal leading-[150%] underline text-[#007D6F]',
    tag: 'p'
  },
  'regular-link': {
    className: 'font-figtree text-base font-normal leading-[150%] text-[#007D6F]',
    tag: 'p'
  },

  'small-default': {
    className: 'font-figtree text-sm font-normal leading-6',
    tag: 'p'
  },
  'small-emphasis': {
    className: 'font-figtree text-sm font-normal font-semibold leading-6',
    tag: 'p'
  },
  'small-underline': {
    className: 'font-figtree text-sm font-normal font-normal leading-6 underline text-[#007D6F]',
    tag: 'p'
  },
  'small-link': {
    className: 'font-figtree text-sm font-normal leading-6 text-[#007D6F]',
    tag: 'p'
  },

  'label-default': {
    className: 'font-figtree text-xs font-normal leading-6',
    tag: 'p'
  },
  'label-emphasis': {
    className: 'font-figtree text-xs font-normal font-semibold leading-6',
    tag: 'p'
  }
};
