import React from 'react';

import { ControllerRenderProps, FieldValues, useController } from 'react-hook-form';
import Icon from 'ui-kit/Icon';
import Typography from 'ui-kit/Typography';

import cn from 'utils/classNames';

interface InputProps {
  icon?: any;
  leftComponent?: (_fields: ControllerRenderProps<FieldValues, any>) => React.ReactNode;
  rightComponent?: (_fields: ControllerRenderProps<FieldValues, any>) => React.ReactNode;
  name: string;
  onChangeValue?: (_value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  shouldUnregister?: boolean;
  type?: string;
  inputClassName?: string;
  width?: string;
  showMaxLength?: boolean;
  autoFocus?: boolean;
  errorValidation?: boolean;
  maxLength?: number;
  autoComplete?: 'on' | 'off';
  onKeyDown?: any;
}

const Input: React.FC<InputProps> = ({
  leftComponent,
  rightComponent,
  name,
  onChangeValue,
  placeholder,
  icon,
  type,
  className,
  disabled,
  shouldUnregister,
  errorValidation,
  inputClassName,
  showMaxLength,
  autoFocus,
  maxLength,
  width,
  autoComplete = 'on',
  ...props
}) => {
  const { field, fieldState, formState } = useController({
    name,
    shouldUnregister
  });

  const { onChange, ...restField } = field;

  const handleChange = (e: { target: HTMLInputElement }) => {
    onChange(e);
    onChangeValue?.(e.target.value);
  };

  const { isValid } = formState;
  const { error, isTouched } = fieldState;

  return (
    <div className={`flex ${width || 'w-full'} w-full flex-col gap-1`}>
      <div
        className={cn(
          'flex items-center rounded border bg-white border-[#C9CCCF] focus-within:border-gray-300',
          (errorValidation || (error && !isValid)) && 'border-orange-500',
          errorValidation && 'bg-[#FFF4F4]',
          disabled && 'bg-[#FAFBFB] text-[#6D7175] border-[#D2D5D8]',
          className
        )}>
        {leftComponent?.(field)}
        <div className="flex-1">
          <input
            type={type}
            autoComplete={autoComplete}
            disabled={disabled}
            placeholder={placeholder}
            onChange={handleChange}
            autoFocus={autoFocus}
            maxLength={maxLength}
            onWheel={e => type === 'number' && e.currentTarget.blur()}
            {...restField}
            {...props}
            className={cn(
              error || errorValidation
                ? 'placeholder:text-orange-500'
                : 'placeholder:text-gray-400',
              icon && 'pr-10',
              disabled && 'bg-gray-100',
              'text-regular outline-none border-transparent focus:border-transparent focus:ring-0 h-full bg-inherit top-3 p-3 w-full text-[16px] text-gray-900 font-medium appearance-none rounded-lg text-inherit',
              inputClassName
            )}
          />
          {icon && (
            <div className="absolute inset-y-0 right-0 flex w-13 rounded-lg bg-white">
              <Icon className="m-auto" icon={icon} color="#565656" size={20} />
            </div>
          )}
        </div>
        {rightComponent?.(field)}
      </div>
      {showMaxLength && (
        <Typography
          variant="regular-default"
          title={`${field.value.length} / ${maxLength}`}
          className="self-end text-[#8C9196]"
        />
      )}
    </div>
  );
};

export default Input;
