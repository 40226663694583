const STORAGE_TOKEN = 'token';
const STORAGE_ROLE = 'role';
const STORAGE_SESSION = 'hasSession';
const STORAGE_COMPLETE_PROFILE = 'completeProfile';
const STORAGE_EMAIL = 'email';
const STORAGE_MX_DOMAIN = 'mx_domain';
const STORAGE_MX_RECORD = 'mx_record';
const STORAGE_USER_ID = 'userId';
const STORAGE_TEST_ID = 'testId';
const STORAGE_TEST_NAME = 'testName';
const STORAGE_TEST_INDEX = 'testIndex';
const STORAGE_APPLICATION_ID = 'applicationId';
const STORAGE_HASH = 'hash';
const STORAGE_COMPANY = 'company';
const STORAGE_EMAIL_VERIFIED = 'emailVerified';
const STORAGE_IS_FIRST_VERIFIED = 'isFirstVerified';
const STORAGE_SHOW_HIRING_FLOW = 'showHiringFlow';

export const localStorageService = (() => {
  function privateGetToken() {
    return localStorage.getItem(STORAGE_TOKEN);
  }
  function privateSetToken(token) {
    localStorage.setItem(STORAGE_TOKEN, token);
  }
  function privateGetIsFirstVerify() {
    return localStorage.getItem(STORAGE_IS_FIRST_VERIFIED);
  }
  function privateSetIsFirstVerify(verify) {
    localStorage.setItem(STORAGE_IS_FIRST_VERIFIED, verify);
  }
  function privateGetEmailVerify() {
    return localStorage.getItem(STORAGE_EMAIL_VERIFIED);
  }
  function privateSetEmailVerify(verify) {
    localStorage.setItem(STORAGE_EMAIL_VERIFIED, verify);
  }
  function privateGetCompany() {
    return localStorage.getItem(STORAGE_COMPANY);
  }
  function privateSetCompany(company) {
    localStorage.setItem(STORAGE_COMPANY, company);
  }
  function privateGetShowHiringFlow() {
    return localStorage.getItem(STORAGE_SHOW_HIRING_FLOW);
  }
  function privateSetShowHiringFlow(show) {
    localStorage.setItem(STORAGE_SHOW_HIRING_FLOW, show);
  }
  function privateGetTestIndex() {
    return localStorage.getItem(STORAGE_TEST_INDEX);
  }
  function privateSetTestIndex(index) {
    localStorage.setItem(STORAGE_TEST_INDEX, index);
  }
  function privateGetTestName() {
    return localStorage.getItem(STORAGE_TEST_NAME);
  }
  function privateSetTestName(name) {
    localStorage.setItem(STORAGE_TEST_NAME, name);
  }
  function privateGetTestId() {
    return localStorage.getItem(STORAGE_TEST_ID);
  }
  function privateSetTestId(id) {
    localStorage.setItem(STORAGE_TEST_ID, id);
  }
  function privateGetHash() {
    return localStorage.getItem(STORAGE_HASH);
  }
  function privateSetHash(hash) {
    localStorage.setItem(STORAGE_HASH, hash);
  }
  function privateGetEmail() {
    return localStorage.getItem(STORAGE_EMAIL);
  }
  function privateSetEmail(email) {
    localStorage.setItem(STORAGE_EMAIL, email);
  }
  function privateGetUserId() {
    return localStorage.getItem(STORAGE_USER_ID);
  }
  function privateSetUserId(id) {
    localStorage.setItem(STORAGE_USER_ID, id);
  }
  function privateGetApplicationId() {
    return localStorage.getItem(STORAGE_APPLICATION_ID);
  }
  function privateSetApplicationId(id) {
    localStorage.setItem(STORAGE_APPLICATION_ID, id);
  }
  function privateGetDomain() {
    return localStorage.getItem(STORAGE_MX_DOMAIN);
  }
  function privateSetDomain(domain) {
    localStorage.setItem(STORAGE_MX_DOMAIN, domain);
  }
  function privateGetRecord() {
    return localStorage.getItem(STORAGE_MX_RECORD);
  }
  function privateSetRecord(record) {
    localStorage.setItem(STORAGE_MX_RECORD, record);
  }
  function privateGetRole() {
    return localStorage.getItem(STORAGE_ROLE);
  }
  function privateSetRole(role) {
    localStorage.setItem(STORAGE_ROLE, role);
  }
  function privateGetSession() {
    return localStorage.getItem(STORAGE_SESSION);
  }
  function privateSetSession(session) {
    localStorage.setItem(STORAGE_SESSION, session);
  }
  function privateGetCompleteProfile() {
    return localStorage.getItem(STORAGE_COMPLETE_PROFILE);
  }
  function privateSetCompleteProfile(complete) {
    localStorage.setItem(STORAGE_COMPLETE_PROFILE, complete);
  }
  function privateClearStorage() {
    localStorage.clear();
    sessionStorage.clear();
  }

  return {
    getToken: privateGetToken,
    setToken: privateSetToken,
    getCompany: privateGetCompany,
    setCompany: privateSetCompany,
    getEmailVerified: privateGetEmailVerify,
    setEmailVerified: privateSetEmailVerify,
    getIsFirstVerified: privateGetIsFirstVerify,
    setIsFirstVerified: privateSetIsFirstVerify,
    getShowHiring: privateGetShowHiringFlow,
    setShowHiring: privateSetShowHiringFlow,
    getHash: privateGetHash,
    setHash: privateSetHash,
    getEmail: privateGetEmail,
    setEmail: privateSetEmail,
    getUserId: privateGetUserId,
    setUserId: privateSetUserId,
    getTestId: privateGetTestId,
    setTestId: privateSetTestId,
    getTestIndex: privateGetTestIndex,
    setTestIndex: privateSetTestIndex,
    getTestName: privateGetTestName,
    setTestName: privateSetTestName,
    getApplicationId: privateGetApplicationId,
    setApplicationId: privateSetApplicationId,
    getDomain: privateGetDomain,
    setDomain: privateSetDomain,
    getRecord: privateGetRecord,
    setRecord: privateSetRecord,
    getRole: privateGetRole,
    setRole: privateSetRole,
    getSession: privateGetSession,
    setSession: privateSetSession,
    getCompleteProfile: privateGetCompleteProfile,
    setCompleteProfile: privateSetCompleteProfile,
    clearStorage: privateClearStorage
  };
})();
