import Logo from 'components/Logo';

export const HeaderCertificate = ({ candidate, assessment, jobRoles = [], jobRole }) => {
  if (!jobRoles.includes(jobRole)) {
    jobRoles.push(jobRole);
  }
  if (window.innerWidth < 400) {
    return (
      <div
        className="flex flex-col gap-6 bg-cover bg-center bg-no-repeat py-12 px-5 md:px-13"
        style={{ backgroundImage: 'linear-gradient(268.81deg, #0A8778 0%, #005349 100%)' }}>
        <div className="flex flex-col items-center gap-[22px]">
          <div className="flex flex-[0.9] flex-col items-center justify-center gap-[6px]">
            <Logo
              to="https://hirenest.com/?utm_source=certificate"
              color="#FFFFFF"
              height={30}
              width={150}
            />
            <p className="text-lg text-white md:text-xl">Pre-Employment Assessments</p>
          </div>
          <p className="flex items-center border-white pl-[22px] text-3xl font-semibold text-white md:text-5xl">
            PEA Certificate
          </p>
        </div>
        <div className="flex flex-col gap-4 md:flex-row md:items-center md:gap-12">
          <div className="flex min-w-[240px] flex-col items-center gap-1 md:items-baseline">
            <p className="text-lg text-white md:text-base">This certificate confirms that</p>
            <p className="text-3xl font-semibold text-white">{`${candidate?.firstName} ${candidate?.lastName}`}</p>
          </div>
          <div className="flex flex-col items-center gap-1 md:items-stretch">
            <p className="text-base text-white">has successfully completed an assessment for</p>
            <p className="text-center text-lg font-semibold text-white md:text-start">
              {assessment?.name}
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center gap-3">
          <p className="text-sm text-white">And he/she is suitable for these job roles:</p>
          <div className="flex flex-wrap justify-center gap-3">
            {jobRoles?.map(item => (
              <div
                key={item}
                className="rounded border border-[#C9CCCF] bg-[#F6F6F7] px-[12px] py-[1px]">
                {item}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  return window.innerWidth < 1024 ? (
    <div
      className="flex flex-col gap-6 bg-cover bg-center bg-no-repeat py-12 px-5 md:px-13"
      style={{ backgroundImage: 'linear-gradient(268.81deg, #0A8778 0%, #005349 100%)' }}>
      <div className="flex gap-[22px]">
        <div className="flex flex-[0.9] flex-col justify-center gap-[6px]">
          <Logo
            to="https://hirenest.com/?utm_source=certificate"
            color="#FFFFFF"
            height={30}
            width={150}
          />
          <p className="text-lg text-white md:text-xl">Pre-Employment Assessments</p>
        </div>
        <p className="flex items-center border-l-[2px] border-white pl-[22px] text-4xl font-semibold text-white md:text-5xl">
          PEA Certificate
        </p>
      </div>
      <div className="flex flex-col gap-4 md:flex-row md:items-start md:gap-12">
        <div className="flex min-w-[240px] flex-col items-center gap-1 md:items-baseline">
          <p className="text-lg text-white md:text-base">This certificate confirms that</p>
          <p className="text-3xl font-semibold text-white">{`${candidate?.firstName} ${candidate?.lastName}`}</p>
        </div>
        <div className="flex flex-col items-center gap-1 md:items-stretch">
          <p className="text-base text-white">has successfully completed an assessment for</p>
          <p className="text-center text-lg font-semibold text-white md:text-start">
            {assessment?.name}
          </p>
        </div>
      </div>
      <div className="flex flex-col items-center gap-3">
        <p className="text-sm text-white">And he/she is suitable for these job roles:</p>
        <div className="flex flex-wrap justify-center gap-3">
          {jobRoles?.map(item => (
            <div
              key={item}
              className="rounded border border-[#C9CCCF] bg-[#F6F6F7] px-[12px] py-[1px]">
              {item}
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <div
      className="flex bg-cover bg-center bg-no-repeat p-12"
      style={{ backgroundImage: 'linear-gradient(268.81deg, #0A8778 0%, #005349 100%)' }}>
      <div className="flex flex-1 flex-col items-center justify-center gap-[6px]">
        <Logo
          to="https://hirenest.com/?utm_source=certificate"
          color="#FFFFFF"
          height={50}
          width={250}
        />
        <p className="text-center text-xl text-white">Pre-Employment Assessments</p>
      </div>
      <div className="border border-r-[3px] border-white" />
      <div className="ml-[32px] flex flex-2 flex-col gap-4">
        <p className="text-5xl font-semibold text-white">PEA Certificate</p>
        <div className="flex items-start gap-[72px]">
          <div className="flex min-w-[240px] flex-col gap-1">
            <p className="text-lg text-white">This certificate confirms that</p>
            <p className="text-3xl font-semibold text-white">{`${candidate?.firstName} ${candidate?.lastName}`}</p>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-lg text-white">has successfully completed an assessment for</p>
            <p className="text-xl font-semibold text-white">{assessment?.name}</p>
          </div>
        </div>
        <div className="mt-3 flex flex-col gap-3 py-4">
          <p className="text-base text-white">And he/she is suitable for these job roles:</p>
          <div className="flex flex-wrap gap-3">
            {jobRoles?.map(item => (
              <div
                key={item}
                className="rounded border border-[#C9CCCF] bg-[#F6F6F7] px-[12px] py-[1px]">
                {item}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
