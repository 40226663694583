import useAppSelector from 'store/hooks/useAppSelector';
import Typography from 'ui-kit/Typography';
import { getModifyDataWithYear } from 'utils/helpers';

export const CandidateTwice = () => {
  const { candidate, applicationStatus } = useAppSelector(store => store.event);
  return (
    <main className="mx-[8px] flex flex-col items-center justify-center shadow-med sm:mx-[20px] md:mx-[30px] md:self-center">
      <div className="flex flex-col justify-center rounded-lg border border-[#C9CCCF] bg-white px-[12px] py-[24px] md:p-12">
        <Typography
          className="mb-[16px]"
          variant="heading-h2"
          title={`Sorry ${candidate?.firstName || applicationStatus?.firstName},`}
        />
        <p>You can't pass this assessment twice.</p>
        <br />
        <p>
          We have your application from{' '}
          {getModifyDataWithYear(candidate?.completedAt || applicationStatus?.completedAt)}.
        </p>
        <br />
        <p>An employer will review it and contact you personally.</p>
        <br />
        <p>Best regards,</p>
        <p>Hirenest team!</p>
      </div>
    </main>
  );
};
