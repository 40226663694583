export const EMPLOYEE_STEPS = '/start';
export const CANDIDATE_INVITE = '/invite';
export const CANDIDATE_SIGN_UP = '/application-sign-up';
export const CANDIDATE_ROADMAP = '/application-roadmap';
export const CANDIDATE_CAMERA_SETUP = '/application-camera-set-up';
export const CANDIDATE_SECTION_INSTRUCTIONS = '/application-section';
export const CANDIDATE_QUESTION = '/application-question';
export const CANDIDATE_CERTIFICATE_OLD = '/pae-certificate';
export const CANDIDATE_CERTIFICATE = '/pea-certificate';
export const CANDIDATE_ASSESSMENT_ARCHIVED = '/application-archived';
export const CANDIDATE_ASSESSMENT_FORBIDDEN = '/application-forbidden';
export const CANDIDATE_ASSESSMENT_TWICE = '/application-twice';
export const CANDIDATE_APPLICATION_FEEDBACK = '/application-feedback';
export const CANDIDATE_APPLICATION_VERIFY = '/candidate/verify';
export const CANDIDATE_APPLICATION_COMPLETED = '/application-completed';
export const CANDIDATE_TEST_FEEDBACK = '/test-feedback';
export const CANDIDATE_EMAIL_CONFIRM = '/application-email-confirm';
export const PRIVACY_POLICY = '/privacy-policy';
export const ERROR_PAGE = '/error-page';
