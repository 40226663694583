import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { candidateCheckValidate } from 'helpers/validation';

import Form from 'ui-kit/Form';
import Typography from 'ui-kit/Typography';
import Input from 'ui-kit/Input';
import ErrorMessage from 'ui-kit/ErrorMessage';
import CheckBox from 'ui-kit/CheckBox';
import Button from 'ui-kit/Button';
import { signInEvent } from 'store/thunks/applicationThunks';
import useAppDispatch from 'store/hooks/useAppDispatch';

const CandidateCheckForm = ({ email, assessmentHash, applicationId, enableCamera }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = useCallback(data => {
    if (!data.isTerms) {
      return;
    }
    delete data.isTerms;
    dispatch(
      signInEvent({
        data: {
          ...data,
          assessmentHash: assessmentHash,
          applicationId
        },
        navigate,
        enableCamera
      })
    );
  }, []);

  return (
    <Form
      mode="onBlur"
      onSubmit={onSubmit}
      defaultValues={{
        firstName: '',
        lastName: '',
        email: email || '',
        isTerms: false
      }}
      resolver={yupResolver(candidateCheckValidate)}
      className="flex flex-col pt-6">
      {({ watch }) => (
        <>
          <div>
            <Typography title="Email" variant="regular-default" />
            <Input name="email" disabled={!!email} />
            <ErrorMessage name="email" />
          </div>
          <div className="flex-1">
            <Typography title="First Name" variant="regular-default" />
            <Input name="firstName" />
            <ErrorMessage name="firstName" />
          </div>
          <div className="flex-1">
            <Typography title="Last Name" variant="regular-default" />
            <Input className="flex-1" name="lastName" />
            <ErrorMessage name="lastName" />
          </div>
          <div className="flex flex-col gap-2">
            <CheckBox
              name="isTerms"
              placeholder={
                <Typography
                  className="mt-[-3.5px] flex flex-wrap gap-1 break-words"
                  variant="regular-default">
                  <Typography title="I have read and I accept the " />
                  <a href="https://hirenest.com/privacy-policy" target="_blank" rel="noreferrer">
                    <Typography className="text-green-500" underline title="privacy policy" />
                  </a>
                </Typography>
              }
            />
            <ErrorMessage name="isTerms" />
          </div>
          <div className="flex justify-center pt-3">
            <Button
              className={`${!watch('isTerms') && 'opacity-50'} w-full sm:max-w-max`}
              variant="primary"
              type="submit">
              Next
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default CandidateCheckForm;
