import React from 'react';

import { useController } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

interface CheckBoxProps {
  name: string;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  component?: (v: boolean) => React.ReactNode;
  onChangeValue?: (v: boolean) => void;
  shouldUnregister?: boolean;
  label?: string;
  value?: string | number;
}

const CheckBox: React.FC<CheckBoxProps> = ({
  name,
  disabled,
  placeholder,
  component,
  onChangeValue,
  value,
  className,
  label,
  shouldUnregister
}) => {
  const { field } = useController({ name, shouldUnregister, defaultValue: false });

  const { onChange, ...restField } = field;

  const handeChange = (event: any) => {
    if (!disabled) {
      onChange(event);
      onChangeValue?.(!field.value);
    }
  };

  return (
    <label className="flex cursor-pointer items-center gap-2">
      <input
        disabled={disabled}
        id={name}
        type="checkbox"
        className={twMerge(
          'w-4 h-4 rounded-sm cursor-pointer border-gray-300 border focus:ring-0 focus:ring-offset-0',
          component && 'hidden',
          className
        )}
        checked={field.value}
        {...restField}
        onChange={handeChange}
        value={value}
      />
      {component?.(!field.value)}
      {placeholder}
      {label && (
        <label className="cursor-pointer" htmlFor={name}>
          {label}
        </label>
      )}
    </label>
  );
};

export default CheckBox;
