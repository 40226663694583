import { Route, Routes } from 'react-router-dom';

import * as routes from './routes';
import ArchivedAssessment from 'pages/Candidate/ArchivedAssessment/ArchivedAssessment';
import { EventCertificate } from 'pages/Candidate/CandidateCertificate/EventCertificate';
import { CandidateMain } from 'pages/Candidate/CandidateMain/CandidateMain';
import { CandidateInvite } from 'pages/Candidate/CandidateInvite/CandidateInvite';
import { CandidateSingUp } from 'pages/Candidate/CandidateSignUp/CandidateSingUp';
import { CandidateRoadmap } from 'pages/Candidate/CandidateRoadmap/CandidateRoadmap';
import { CandidateQuestion } from 'pages/Candidate/CandidateQuestion/CandidateQuestion';
import CandidateApplicationFeedback from 'pages/Candidate/CandidateApplicationFeedback/CandidateApplicationFeedback';
import { CandidateTestFeedback } from 'pages/Candidate/CandidateTestFeedback/CandidateTestFeedback';
import { EmailConfirm } from 'pages/Candidate/EmailConfirm/EmailConfirm';
import { CandidateVerify } from 'pages/Candidate/CandidateVerify/CandidateVerify';
import { CandidateCompleted } from 'pages/Candidate/CandidateCompleted/CandidateCompleted';
import { CandidateTwice } from 'pages/Candidate/CandidateTwice/CandidateTwice';
import { SectionIntro } from 'pages/Candidate/CandidateSectionInstructions/CandidateSectionInstructions';
import ErrorHandlerLayout from 'pages/ErrorHandlerLayot/ErrorHandlerLayout';
import { CandidateCameraSetUp } from 'pages/Candidate/CandidateCameraSetUp/CandidateCameraSetUp';

const CandidateEventRoutes = () => {
  return (
    <Routes>
      <Route element={<CandidateMain />}>
        <Route index path={routes.CANDIDATE_INVITE} element={<CandidateInvite />} />
        <Route path={routes.CANDIDATE_SIGN_UP} element={<CandidateSingUp />} />
        <Route path={routes.CANDIDATE_CAMERA_SETUP} element={<CandidateCameraSetUp />} />
        <Route path={routes.CANDIDATE_ROADMAP} element={<CandidateRoadmap />} />
        <Route path={routes.CANDIDATE_SECTION_INSTRUCTIONS} element={<SectionIntro />} />
        <Route path={routes.CANDIDATE_QUESTION} element={<CandidateQuestion />} />
        <Route path={routes.CANDIDATE_ASSESSMENT_ARCHIVED} element={<ArchivedAssessment />} />
        <Route path={routes.CANDIDATE_ASSESSMENT_TWICE} element={<CandidateTwice />} />
        <Route
          path={routes.CANDIDATE_APPLICATION_FEEDBACK}
          element={<CandidateApplicationFeedback />}
        />
        <Route path={routes.CANDIDATE_EMAIL_CONFIRM} element={<EmailConfirm />} />
        <Route path={routes.CANDIDATE_TEST_FEEDBACK} element={<CandidateTestFeedback />} />
        <Route path={routes.CANDIDATE_APPLICATION_COMPLETED} element={<CandidateCompleted />} />
      </Route>
      <Route path={routes.CANDIDATE_APPLICATION_VERIFY} element={<CandidateVerify />} />
      <Route path={routes.CANDIDATE_CERTIFICATE_OLD} element={<EventCertificate />} />
      <Route path={routes.CANDIDATE_CERTIFICATE} element={<EventCertificate />} />
      <Route path={routes.ERROR_PAGE + '/:code'} element={<ErrorHandlerLayout />} />
      <Route path={'/'} element={<ErrorHandlerLayout />} />
    </Routes>
  );
};

export default CandidateEventRoutes;
