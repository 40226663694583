import Footer from 'components/Footer';
import Loader from 'components/Loader';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { HeaderCertificate } from './HeaderCertificate/HeaderCertificate';
import 'react-circular-progressbar/dist/styles.css';
import { FooterCertificate } from './FooterCertificate/FooterCertificate';
import { ResultsOveralCertificate } from './ResultsOveralCertificate/ResultsOveralCertificate';
import { ResultsDetailsCertificate } from './ResultsDetailsCertificate/ResultsDetailsCertificate';
import { InfoCertificate } from './InfoCertificate/InfoCertificate';
import { getCertificate } from 'store/thunks/applicationThunks';
import useAppDispatch from 'store/hooks/useAppDispatch';
import useAppSelector from 'store/hooks/useAppSelector';

export const EventCertificate = () => {
  const dispatch = useAppDispatch();
  const { hash, search, pathname } = useLocation();
  const urlParams = new URLSearchParams(search);
  urlParams.delete('info');
  const preparedHash = hash.substring(1);

  const { loading, certificate } = useAppSelector(store => store.event);

  const copyLink = window.location.origin + pathname + '?' + urlParams.toString() + hash;

  useEffect(() => {
    preparedHash && dispatch(getCertificate(preparedHash));
  }, [preparedHash]);

  return (
    <div className="flex h-screen w-full flex-col justify-between">
      {loading && <Loader />}
      {search.includes('info') && <InfoCertificate copyLink={copyLink} />}
      <HeaderCertificate
        candidate={certificate?.candidate?.[0]}
        assessment={certificate?.assessment}
        jobRoles={certificate?.template?.jobRoles}
        jobRole={certificate?.assessment?.jobRole}
      />
      <ResultsOveralCertificate completedAt={certificate?.completedAt} tests={certificate?.tests} />
      <ResultsDetailsCertificate tests={certificate?.tests} />
      <FooterCertificate />
      <Footer />
    </div>
  );
};
