import { faStopwatch } from '@fortawesome/pro-regular-svg-icons';
import Icon from 'ui-kit/Icon';
import { secondsToHms } from 'utils/helpers';

const TimeBadget = ({ time, className, showIcon = false }) => {
  return (
    <span
      className={`flex h-[22px] items-center gap-[4.5px] rounded border-[0.5px] border-[#CBD6D6] bg-[#EEF2F2] py-[1px] px-[6px] text-sm font-semibold text-[#05443D] shadow-small ${
        className || ''
      }`}>
      {showIcon && <Icon icon={faStopwatch} size={14} color="#5C5F62" />}
      <p className="w-max whitespace-nowrap">{secondsToHms(time) || '0 min'}</p>
    </span>
  );
};

export default TimeBadget;
