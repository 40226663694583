import default_hirenest_logo from 'assets/hirenest-logo.svg';
import Countdown from 'components/Countdown/Countdown';
import { useLocation } from 'react-router-dom';
import Typography from 'ui-kit/Typography';

export const CandidateHeader = ({
  companyLogo,
  name,
  eventQuestion,
  onCompliteQuestionHandler,
  currentTest
}) => {
  const location = useLocation();

  return (
    <header className="sticky top-0 z-10 flex w-full flex-col items-start gap-3 border border-b border-gray-300 bg-white px-[20px] py-[16px] md:flex-row md:items-center md:p-5">
      <img className="h-[32px]" src={companyLogo || default_hirenest_logo} />
      <div className="flex flex-col gap-[8px]">
        <Typography
          className="text-xl font-medium text-[#6D7175] md:text-h1 md:font-semibold"
          title={`${name || 'Assessment name'}`}
        />
        {eventQuestion &&
          (location.pathname === '/application-question' ||
            location.pathname === '/application-roadmap' ||
            location.pathname === '/application-section') && (
            <Typography
              className="text-h2 font-medium text-[#6D7175] md:text-lg"
              title={eventQuestion?.testName || currentTest?.name}
            />
          )}
      </div>
      <div className="flex w-full flex-1 flex-col items-end gap-[9px] md:pr-[50px]">
        {eventQuestion?.questionNumber && (
          <div className="relative flex h-[18px] w-full items-center justify-center rounded-sm border border-gray-300 bg-white md:w-[350px]">
            <div
              className="absolute left-0 bottom-0 h-[17px] rounded-sm bg-[#DBDDDF]"
              style={{
                width: `${(eventQuestion?.questionNumber / eventQuestion?.questionsCount) * 100}%`
              }}
            />
            <p className="z-10 text-sm text-[#6D7175]">{`Question ${eventQuestion?.questionNumber} out of ${eventQuestion?.questionsCount}`}</p>
          </div>
        )}
        {eventQuestion?.timeLimit && (
          <Countdown
            onTimeUp={onCompliteQuestionHandler}
            timeLimit={eventQuestion?.timeLimit}
            timeLeft={eventQuestion?.timeLeft}
            question={eventQuestion?.questionNumber}
          />
        )}
      </div>
    </header>
  );
};
