import {
  age,
  employment,
  gender,
  industry,
  occupation,
  educationLevel,
  years
} from 'config/selectConfig';
import { SelectCustom } from 'ui-kit/SelectCustom';
import Typography from 'ui-kit/Typography';
import Form from 'ui-kit/Form';
import Textarea from 'ui-kit/Textarea';
import CheckBox from 'ui-kit/CheckBox';
import Button from 'ui-kit/Button';
import { useEffect } from 'react';
import { getCountries } from 'store/thunks/applicationThunks';
import useAppDispatch from 'store/hooks/useAppDispatch';
import useAppSelector from 'store/hooks/useAppSelector';
import Loader from 'components/Loader';

const AssessmentFeedbackForm = ({ onSubmit, onSave, feedback }) => {
  const dispatch = useAppDispatch();

  const { countries, loading } = useAppSelector(store => store.event);

  useEffect(() => {
    dispatch(getCountries());
  }, []);
  return (
    <Form
      defaultValues={{
        feedback
      }}
      onSubmit={onSave}>
      {({ handleSubmit, getValues }) => (
        <>
          <div className="space-y-5 border-y border-gray-400 p-4">
            {loading && <Loader />}
            <Typography
              className="text-center"
              title="How was your experience with Assessment?"
              variant="heading-h3"
            />
            <Textarea name="feedback.explain" placeholder="Please tell us why..." />
            <CheckBox
              name="feedback.anonymousFeedback"
              placeholder={<Typography variant="small-default" title="Leave Anonymous Feedback" />}
            />
          </div>
          <div className="space-y-5 p-4">
            <Typography
              className="text-center"
              title="Please answer few more questions for our analytics :)"
              variant="heading-h3"
            />
            <div className="flex flex-col gap-4 sm:flex-row">
              <div className="flex-1">
                <SelectCustom
                  isSearchable={false}
                  onChangeValue={handleSubmit(onSave)}
                  name="feedback.age"
                  placeholder="Age"
                  options={age}
                />
              </div>
              <div className="flex-1">
                <SelectCustom
                  name="feedback.gender"
                  onChangeValue={handleSubmit(onSave)}
                  isSearchable={false}
                  placeholder="Gender"
                  options={gender}
                />
              </div>
            </div>
            <div className="flex flex-col gap-4 sm:flex-row">
              <div className="flex-1">
                <SelectCustom
                  name="feedback.ethnicity"
                  onChangeValue={handleSubmit(onSave)}
                  placeholder="Country"
                  options={countries}
                />
              </div>
              <div className="flex-1">
                <SelectCustom
                  name="feedback.occupation"
                  onChangeValue={handleSubmit(onSave)}
                  placeholder="Occupation"
                  options={occupation}
                />
              </div>
            </div>
            <div className="flex flex-col gap-4 sm:flex-row">
              <div className="flex-1">
                <SelectCustom
                  name="feedback.industry"
                  onChangeValue={handleSubmit(onSave)}
                  placeholder="Industry"
                  options={industry}
                />
              </div>
              <div className="flex-1">
                <SelectCustom
                  name="feedback.employment"
                  onChangeValue={handleSubmit(onSave)}
                  placeholder="Employment"
                  options={employment}
                />
              </div>
            </div>
            <div className="flex flex-col gap-4 sm:flex-row">
              <div className="flex-1">
                <SelectCustom
                  name="feedback.experince"
                  onChangeValue={handleSubmit(onSave)}
                  isSearchable={false}
                  placeholder="Years of experience of role"
                  options={years}
                />
              </div>
              <div className="flex-1">
                <SelectCustom
                  name="feedback.education"
                  onChangeValue={handleSubmit(onSave)}
                  placeholder="Highest education level"
                  options={educationLevel}
                />
              </div>
            </div>
            <Button
              title="Submit Feedback"
              className="w-full justify-center"
              onClick={() => {
                const values = getValues();
                onSubmit(values);
              }}
              variant="primary"
            />
          </div>
        </>
      )}
    </Form>
  );
};

export default AssessmentFeedbackForm;
