import * as Yup from 'yup';

export function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

const email = Yup.string()
  .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blankspaces')
  .email('Enter the correct email')
  .matches(
    /([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)/g,
    'The field must not contain any numbers or symbols'
  )
  .required('Email is required');

// const phoneRegExp =
//   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const companyName = Yup.string()
  .nullable()
  .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blankspaces')
  .min(1, 'Enter at least 1 characters')
  .max(100, 'Enter up to 100 characters')
  .required('Enter at least 1 characters');

const section = Yup.string()
  .nullable()
  .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blankspaces')
  .min(1, 'Enter at least 1 characters')
  .max(100, 'Enter up to 100 characters')
  .required('Enter at least 1 characters');

const firstName = Yup.string()
  .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blankspaces')
  .min(2, 'Enter at least 2 characters')
  .max(24, 'Enter up to 24 characters')
  .required('Enter at least 2 characters');

const phone = Yup.string()
  .required('Phone number is required')
  .matches(/^\+?[1-9]\d{1,14}$/, 'Invalid phone number');

const lastName = Yup.string()
  .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blankspaces')
  .min(2, 'Enter at least 2 characters')
  .max(24, 'Enter up to 24 characters')
  .required('Enter at least 2 characters');

const title = Yup.string()
  .nullable()
  .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blankspaces')
  .min(2, 'Enter at least 2 characters')
  .max(24, 'Enter up to 24 characters')
  .matches(/^[a-zA-Z\s'&.-]+$/g, 'The field must not contain any numbers or symbols')
  .required('Enter at least 2 characters');

const biography = Yup.string()
  .nullable()
  .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blankspaces')
  .min(1, 'Min - 1 symbol')
  .max(1000, 'Max - 1000 symbols')
  .required('Enter your biography');

const linkedIn = Yup.string()
  .nullable()
  .notRequired()
  .when('linkedIn', {
    is: value => value?.length,
    then: rule => rule.matches('^(http(s)?://)?([w]+.)?linkedin.com/(pub|in|profile)/')
  });

const behance = Yup.string()
  .nullable()
  .notRequired()
  .when('behance', {
    is: value => value?.length,
    then: rule =>
      rule.matches(
        /(http(s?):\/\/)?(www\.)?behance\.([a-z])+\/([A-Za-z0-9]{1,})\/?$/,
        'Enter correct Behance url, example: https://www.behance.com/username'
      )
  });

const dribbble = Yup.string()
  .nullable()
  .notRequired()
  .when('dribbble', {
    is: value => value?.length,
    then: rule =>
      rule.matches(
        /(http(s?):\/\/)?(www\.)?dribbble\.([a-z])+\/([A-Za-z0-9]{1,})\/?$/,
        'Enter correct Dribbble url, example: https://www.dribbble.com/username'
      )
  });

const website = Yup.string()
  .nullable()
  .notRequired()
  .when('website', {
    is: value => value?.length,
    then: rule =>
      rule.matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?\/?$/,
        'Enter correct website url, example: https://www.google.com/'
      )
  });

const facebook = Yup.string()
  .nullable()
  .notRequired()
  .when('facebook', {
    is: value => value?.length,
    then: rule =>
      rule.matches(
        /(?:https?:\/\/)?(?:www\.)?facebook\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w-]*\/)*?(\/)?([\w\-.]{5,})\/?/,
        'Enter correct Facebook url'
      )
  });

const password = Yup.string().required('Password is required');
// .matches(/^[\S]+$/, 'The user’s password shouldn’t contain the spaces')
// .max(32, 'Maximum number of ranks 32');
// .min(8, 'Minimum number of ranks 8!')
// .matches(
//   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!$%^&*()_+|~=`{}[\]:";'<>?,./@ \\#])(?=.{8,})/,
//   'Must contain one uppercase, one number and one special case character')

const passwordConfirmation = Yup.string()
  .required('Password is required')
  .test('passwords-match', 'Passwords do not match', function (value) {
    return this.parent.newPassword === value;
  });
// .min(8, 'Minimum number of ranks 8!')
// .matches(
//   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!$%^&*()_+|~=`{}[\]:";'<>?,./@ \\#])(?=.{8,})/,
//   'Must contain one uppercase, one number and one Special Case Character'
// );

// const tel = Yup.string().matches(phoneRegExp, 'Phone number is not valid');

// Assessment

const assessmentName = Yup.string()
  .nullable()
  .label('Assessment Name')
  .min(1, 'Assessment Name should contain at least 1 symbol')
  .max(100, 'Max asssessment name 100 symbols')
  .required();

const assessmentDescr = Yup.string()
  .nullable()
  .label('Message to Candidate')
  .min(1, 'Message to Candidate should contain at least 1 symbol')
  .max(1000, 'Max - 1000 symbols')
  .required();

const questionText = Yup.string()
  .nullable()
  .label('Question')
  .matches(/^\s*\S[\s\S]*$/, 'Question text cannot contain only blankspaces')
  .required('Enter your question text');

// const releaseNotes = Yup.string()
//   .nullable()
//   .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blankspaces')
//   .min(1, 'Min - 1 symbol')
//   .max(2000, 'Max - 2000 symbols')
//   .required('Enter your release notes');

const emailMessage = Yup.string()
  .nullable()
  .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blankspaces')
  .min(1, 'Min - 1 symbol')
  .max(2000, 'Max - 2000 symbols')
  .required('Enter your message for employer');

const isTerms = Yup.bool();

const age = Yup.number()
  .typeError('Must be a number')
  .min(1)
  .max(150)
  .transform((_, val) => (val ? Number(val) : null))
  .nullable(true);

const note = Yup.string()
  .min(1, 'Enter a note please')
  .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blankspaces')
  .required('Enter a note please');

const subscriptionName = Yup.string()
  .min(3, 'Enter a name')
  .max(50, 'Too long')
  .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blankspaces')
  .required('Enter a name');

const description = Yup.string()
  .min(3, 'Enter a description')
  .max(150, 'Too long')
  .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blankspaces')
  .required('Enter a description');

const publications = Yup.number()
  .min(0, 'Add publications number')
  .typeError('Add publications number')
  .required('Add publications number');

const lifetime = Yup.number()
  .integer('Must be an integer number')
  .min(0, 'Add lifetime')
  .typeError('Add lifetime')
  .required('Add lifetime');

const duration = Yup.mixed().oneOf(['year']);
const type = Yup.mixed().oneOf(['paid', 'free']);

const cost = Yup.number()
  .nullable()
  .typeError('Add cost more than 0')
  .transform(emptyStringToNull)
  .when('pricing', (pricing, field) => (pricing === 'paid' ? field.required() : field));

const addOn = Yup.number()
  .nullable()
  .min(0, 'Add addons cost more than 0')
  .transform(emptyStringToNull)
  .when('pricing', (pricing, field) => (pricing === 'paid' ? field.required() : field));

export const NoteSchema = Yup.object()
  .shape({
    note
  })
  .required();

export const SettingsValidation = Yup.object().shape({
  recommendedScore: Yup.number()
    .integer('Must be an integer number')
    .max(100, 'Max value 100')
    .transform(emptyStringToNull)
    .nullable()
});

export const AssesmentFeedbackSchema = Yup.object().shape({ age });

// export const TestSettingsSchema = Yup.object().shape({
//   testName,
//   tagline,
//   testSummary,
//   testDescription
// });

// export const NewTestSettingsSchema = Yup.object({
//   testName,
//   tagline,
//   testSummary,
//   testDescription
// });

export const NewQuestionSchema = Yup.object().shape({
  text: questionText
});

export const AddTeamMemberSchema = Yup.object().shape({
  firstName,
  lastName,
  email
});
export const EditCompanySchema = Yup.object().shape({
  companyName
});

export const EditUserFullSchema = Yup.object().shape({
  firstName,
  lastName,
  email,
  password
});

export const EditAuthorSchema = Yup.object().shape(
  {
    firstName,
    lastName,
    title,
    bio: biography,
    linkedin: linkedIn,
    facebook,
    behance,
    dribbble,
    website
  },
  [
    ['linkedIn', 'linkedIn'],
    ['facebook', 'facebook'],
    ['behance', 'behance'],
    ['dribbble', 'dribbble'],
    ['website', 'website']
  ]
);

export const EditEmloyerSchema = Yup.object().shape({
  firstName,
  lastName
});

export const InviteCandidateSchema = Yup.object().shape({
  email
});

export const SignInSchema = Yup.object().shape({
  email,
  password
});

export const SignUpSchema = Yup.object().shape({
  email,
  subscribeForEmails: isTerms
});

export const profileCompleteSchema = Yup.object().shape({
  firstName,
  lastName,
  companyName,
  phone
});

export const AddContentAdminSchema = Yup.object().shape({
  firstName,
  lastName,
  email,
  password
});

export const SignUpEmployeeSchema = Yup.object().shape({
  firstName,
  lastName
});

export const ResetPasswordSchema = Yup.object().shape({
  email
});

export const InviteEmployerSchema = Yup.object({
  firstName,
  lastName,
  companyName,
  email
});

export const createSectionSchema = Yup.object({
  name: section
});

export const publishSubscriptionPlan = Yup.object({
  name: subscriptionName,
  description,
  duration,
  pricing: type,
  cost,
  addons: addOn,
  publicationLimit: publications,
  publicationLifetime: lifetime
});

export const draftSubscriptionPlan = Yup.object({
  name: subscriptionName,
  pricing: type,
  cost,
  publicationLimit: publications,
  publicationLifetime: lifetime
});

export const InviteAuthorSchema = Yup.object({
  firstName,
  lastName,
  email
});

export const InviteAdminSchema = Yup.object({
  email,
  firstName: Yup.string()
    .nullable()
    .label('First Name')
    .min(1, 'Min - 1 symbol')
    .max(24, 'Max - 24 symbols'),
  lastName: Yup.string()
    .nullable()
    .label('Last Name')
    .min(1, 'Min - 1 symbol')
    .max(24, 'Max - 24 symbols')
});

export const EditAdminSchema = Yup.object({
  email,
  firstName: Yup.string()
    .nullable()
    .label('First Name')
    .min(1, 'Min - 1 symbol')
    .max(24, 'Max - 24 symbols'),
  lastName: Yup.string()
    .nullable()
    .label('Last Name')
    .min(1, 'Min - 1 symbol')
    .max(24, 'Max - 24 symbols'),
  status: Yup.mixed().oneOf(['active', 'disabled', 'invited'])
});

export const resendLinkSchema = Yup.object().shape({
  email
});

export const draftAssessmentSchema = Yup.object().shape({
  name: assessmentName
});

export const publishAssessmentSchema = Yup.object().shape({
  name: assessmentName,
  description: assessmentDescr
});

export const createPasswordSchema = Yup.object().shape({
  newPassword: password
});

export const editPasswordSchema = Yup.object().shape({
  password,
  newPassword: password,
  confirmPassword: passwordConfirmation
});

export const editMailSchema = Yup.object().shape({
  password,
  newEmail: email
});

export const candidateCheckValidate = Yup.object().shape({
  firstName,
  lastName,
  email
});

export const ManageEmployerSchema = Yup.object().shape({
  message: emailMessage
});

export const TestCategorySchema = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .matches(/^\s*\S[\s\S]*$/, 'Category cannot contain only blankspaces')
    .max(50, 'Max - 50 symbols')
});
export const AnswerChoisesSchema = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blankspaces')
    .max(50, 'Max - 50 symbols')
    .required(),
  values: Yup.string().max(1000, 'Max - 1000 symbols').required()
});

export const createNewQuestion = Yup.object({
  text: questionText,
  timeLimit: Yup.number().typeError('Time limit is required').required(),
  answers: Yup.array().when('type', type => {
    if (type && type !== 'essay') {
      return Yup.array()
        .of(
          Yup.object({
            score: Yup.number().typeError('Score must be a number').required(),
            attachment: Yup.mixed(),
            text: Yup.string()
              .trim()
              .label('Answer test')
              .max(1000)
              .when('attachment', (attachment, field) => (attachment ? field : field.required()))
          })
        )
        .min(2, 'Question should conatin at least 2 answers');
    }
  })
});

export const SaveNewTest = Yup.object({
  name: Yup.string()
    .matches(/^\s*\S[\s\S]*$/, 'Test name cannot contain only blankspaces')
    .min(1, 'Test name should contain min - 1 symbol')
    .max(75, 'Test name should contain max - 75 symbol'),
  difficulty: Yup.string().required().label('Difficulty'),
  language: Yup.string().required().label('Language')
});

export const CreateNewTest = Yup.object({
  sections: Yup.array()
    .of(
      Yup.object({
        instructions: Yup.string(),
        showInstructions: Yup.boolean(),
        name: Yup.string().required().label('Section Name'),
        questions: Yup.array().min(1).label('Questions').required()
      }).label('Section')
    )
    .label('Sections'),
  category: Yup.mixed().required().label('Category'),
  name: Yup.string()
    .matches(/^\s*\S[\s\S]*$/, 'Test name cannot contain only blankspaces')
    .min(1, 'Test name should contain min - 1 symbol')
    .max(50, 'Test name should contain max - 50 symbols'),
  outcomes: Yup.string().label('Test Outcomes'),
  whyMeasure: Yup.object({
    title: Yup.string().nullable().max(100, 'Why measure title should contain max - 100 symbols'),
    text: Yup.string().label('Why Measure')
  }),
  tagline: Yup.string()
    .nullable()
    .matches(/^\s*\S[\s\S]*$/, 'Test tagline cannot contain only blankspaces')
    .min(1, 'Test tagline should contain min - 1 symbol')
    .max(200, 'Test tagline should contain max - 200 symbols')
    .required('Enter your test tagline'),
  summary: Yup.string().required().label('Test Summary'),
  difficulty: Yup.string().required().label('Difficulty'),
  language: Yup.string().required().label('Language'),
  recommendedScore: Yup.number()
    .integer('Must be an integer number')
    .max(100, 'Max value 100')
    .transform(emptyStringToNull)
    .nullable()
});
