import Typography from 'ui-kit/Typography';

const ErrorHandlerLayout = () => (
  <div className="disable-select flex h-screen max-h-full flex-col justify-between">
    <header className="sticky top-0 z-10 flex h-[64px] w-full items-center gap-3 border border-b border-gray-300 bg-white p-5" />
    <main className="mx-[20px] mt-[20px] flex h-full flex-col items-center md:justify-center">
      <div
        className="flex flex-col items-center justify-center space-y-3 rounded-md bg-[#FFFFFF] px-[18px] py-[24px] shadow-[0_1px_8px_0px_rgba(68,68,68,0.12)]
        md:p-[48px]">
        <div className="items-left">
          <Typography
            className="text-[24px] font-medium text-[#082623]"
            title="Hey, something went wrong"
          />
          <Typography
            className="mt-[12px] mb-[24px] text-[16px] font-normal"
            title="We can’t find this page. But we can help you find new opportunities."
          />
        </div>
        <a
          className="flex h-[47px] w-full items-center justify-center rounded-md bg-[#007D6F] px-[16px] py-[8px] text-lg font-semibold text-[#FFFFFF] md:h-[40px] md:max-w-max md:text-[16px]"
          href="mailto:support@hirenest.com">
          Get Support
        </a>
      </div>
    </main>
  </div>
);

export default ErrorHandlerLayout;
