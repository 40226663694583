import { Editor, EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

export const HtmlTextReplacer = ({ html, ...props }) => {
  if (!html) {
    return <p></p>;
  }

  const myBlockStyleFn = contentBlock => {
    const type = contentBlock.getType();
    if (type === 'blockquote') {
      return 'custom-blockquote';
    } else if (type === 'code') {
      return 'public-DraftStyleDefault-code-block';
    } else if (type === 'unstyled') {
      return 'custom-paragraph';
    } else if (type === 'unordered-list-item') {
      return 'custom-ul';
    } else if (type === 'ordered-list-item') {
      return 'custom-ol';
    }
    return '';
  };

  const preventSelection = event => {
    event.preventDefault();
  };

  // Convert HTML to ContentState using htmlToDraft
  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  const editorState = EditorState.createWithContent(contentState);

  return (
    <div style={props?.style} className={`${props?.className}`} onPointerDown={preventSelection}>
      <Editor blockStyleFn={myBlockStyleFn} editorState={editorState} readOnly={true} />
      <style>
        {`
          .custom-paragraph {
            margin-top: 6px;
            margin-bottom: 6px;
          }
          .custom-ul {
            list-style-type: disc; /* or your preferred style */
            margin-left: 20px; /* Adjust as needed */
          }
          .custom-ol {
            list-style-type: decimal; /* or your preferred style */
            margin-left: 20px; /* Adjust as needed */
          }
        `}
      </style>
    </div>
  );
};
