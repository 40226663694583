import testCover1x from 'assets/testCover@1x.jpg';
import testCover2x from 'assets/testCover@2x.jpg';

export const CoverTest = ({ url, size }) => (
  <img
    style={{ maxHeight: `${size}px` }}
    src={url || testCover1x}
    srcSet={url || `${testCover1x} 1x, ${testCover2x} 2x`}
    className="w-full object-cover"
    alt="test cover"
  />
);
