import Lottie from 'react-lottie';
import emailConfirm from 'assets/lotties/emailConfirm.json';
import { generateSniperLink } from 'utils/gerenateSniperLink';
import { useEffect, useState } from 'react';
import Typography from 'ui-kit/Typography';
import Button from 'ui-kit/Button';
import useAppSelector from 'store/hooks/useAppSelector';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: emailConfirm,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

export const EmailConfirm = () => {
  const { candidate } = useAppSelector(store => store.event);
  const [sniperLink, setSniperLink] = useState({ url: '', name: '' });

  useEffect(() => {
    if (candidate?.email) {
      setSniperLink(generateSniperLink(null, candidate?.email));
    }
  }, [candidate?.email]);

  return (
    <main className="container mx-[8px] flex max-w-[576px] flex-col gap-6 self-center rounded-xl border border-gray-400 bg-white py-6 px-[12px] shadow-med sm:mx-[20px] md:self-auto md:px-12">
      <Lottie options={defaultOptions} height={200} width={200} />
      <div className="flex flex-col gap-[6px]">
        <Typography variant="heading-h2" title="Confirm your email" />
        <Typography
          variant="body-regular"
          title="Thanks for taking the first step to get started with Hirenest. To ensure you get the most out of our platform, please confirm your email address by clicking the link we sent you. We look forward to helping you find your perfect job!"
        />
      </div>
      <div className="flex justify-center pt-3">
        {sniperLink?.url && (
          <Button
            onClick={() => window.location.replace(sniperLink?.url)}
            className="w-full sm:max-w-max"
            variant="primary"
            type="submit">
            {`Open ${sniperLink?.name}`}
          </Button>
        )}
      </div>
    </main>
  );
};
